<template>
    <v-card>
        <v-list v-model:opened="openSideBar">
            <v-list-group value="Schiffe">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        title="Schiffe"
                    ></v-list-item>
                </template>

                <v-list-item
                    v-for="ship in ships"
                    :key="ship.id"
                    :value="ship.name"
                    @click="$emit('highlight', ship.id)"
                >
                    <div class="d-flex align-center justify-start">
                        <div class="rounded ship-indicator mr-2"
                             :style="{'background-color': ship.color}"></div>
                        <v-list-item-title v-text="ship.name"></v-list-item-title>
                    </div>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: 'watch-side-bar',
    props: {
        ships: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        showSideBar: false,
        openSideBar: [],
    })
}
</script>

<style lang="scss">
.ship-indicator {
    width: 16px;
    height: 16px;
}
</style>
