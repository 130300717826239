export const roles = {
    user: 'user',
    admin: 'admin',
}

export const translations = {
    user: 'Regattaleitung',
    admin: 'Admin',
}

export function translateRole(role) {
   return translations[role];
}