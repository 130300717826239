<template>
    <v-container class="mt-16">
        <v-row justify="center">
            <v-col cols="12" sm="8">
                <div class="text-h4 mb-8 mt-12 text-center">Benutzerverwaltung</div>
                <v-table>
                    <thead>
                    <tr>
                        <th class="text-left">E-Mail</th>
                        <th class="text-left">Rolle</th>
                        <th class="text-right">Aktionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.email }}</td>
                        <td>{{ translateRole(user.role) }}</td>
                        <td class="d-flex justify-end align-center">
                            <v-icon class="mr-2" icon="mdi-pencil" @click="openUpdateUser(user.id)"></v-icon>
                            <v-icon icon="mdi-delete" @click="openDeleteUser(user.id)"></v-icon>
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col cols="12" sm="8" class="d-flex justify-end">
                <v-btn color="primary" @click="createUserDialog = true">Benutzer erstellen</v-btn>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-icon icon="mdi-close" @click="snackbar = false"></v-icon>
            </template>
        </v-snackbar>

        <v-dialog v-model="createUserDialog" max-width="800">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-start">
                                <div class="text-h5">Benutzer erstellen</div>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="E-Mail"
                                    v-model="email"
                                    :error-messages="formErrors.email"
                                    @update:modelValue="resetError('email')"
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    label="Passwort"
                                    v-model="password"
                                    :error-messages="formErrors.password"
                                    @update:modelValue="resetError('password')"
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    label="Passwort bestätigen"
                                    v-model="passwordConfirm"
                                    @update:modelValue="resetError('passwordConfirm')"
                                ></v-text-field>
                                <v-select
                                    :items="roleSelectItems"
                                    label="Rolle"
                                    v-model="role"
                                    :error-messages="formErrors.role"
                                    @update:modelValue="resetError('role')"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-alert class="mt-5" type="error" density="comfortable" variant="tonal"
                                         v-if="errorPasswordConfirmMsg">
                                    Passwörter stimmen nicht über ein
                                </v-alert>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-space-between">
                                <v-btn @click="createUserDialog = false">Abbrechen</v-btn>
                                <v-btn color="success" @click="createUser()">Benutzer erstellen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="updateUserDialog" max-width="800">
            <v-card v-if="formUser">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-start">
                                <div class="text-h5">Benutzer {{ formUser.email }} aktualisieren:</div>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    label="E-Mail"
                                    :error-messages="formErrors.email"
                                    @update:modelValue="resetError('email')"
                                ></v-text-field>
                                <v-select
                                    v-model="role"
                                    :items="roleSelectItems"
                                    label="Rolle"
                                    :error-messages="formErrors.role"
                                    @update:modelValue="resetError('role')"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-space-between">
                                <v-btn @click="updateUserDialog = false">Abbrechen</v-btn>
                                <v-btn color="info" @click="updateUser()">Aktualisieren</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteUserDialog" max-width="800">
            <v-card v-if="formUser">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-start">
                                <div class="text-h5">Benutzer {{ formUser.email }} löschen?</div>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-start">
                                <v-btn @click="deleteUserDialog = false">Abbrechen</v-btn>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn color="error" @click="deleteUser()">Löschen</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { roles, translateRole } from '@/types/Role';
import BackendApi from '@/scripts/BackendApi';
import userCheck from '@/scripts/UserCheck';
import form from '@/scripts/Form';

export default {
    name: 'UserManagementView',
    mixins: [form, userCheck],
    data: () => ({
        users: [],
        email: null,
        password: null,
        passwordConfirm: null,
        role: null,
        createUserDialog: false,
        updateUserDialog: false,
        deleteUserDialog: false,
        errorPasswordConfirmMsg: false,
        formUser: null,
        snackbar: false,
        snackbarText: null,
        snackbarColor: null,
        neededRoles: [roles.admin],
    }),
    mounted() {
        this.fetchUsers();
    },
    methods: {
        translateRole: translateRole,
        async fetchUsers() {
            try {
                this.users = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/users'))?.data;
            } catch {
                this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
            }
        },
        async createUser() {
            if (this.password !== this.passwordConfirm) {
                this.password = null;
                this.passwordConfirm = null;
                this.errorPasswordConfirmMsg = true;

                return;
            }

            BackendApi
                .post(process.env.VUE_APP_BACKEND_URL + '/api/users', {
                    email: this.email,
                    password: this.password,
                    role: this.role
                })
                .then(() => {
                    this.createUserDialog = false;
                    this.fetchUsers();
                    this.displaySnackbar('Benutzer erfolgreich erstellt');
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
                });
        },
        openUpdateUser(userId) {
            this.formUser = this.users.find(user => user.id === userId);
            this.updateUserDialog = true;
        },
        updateUser() {
            const payload = Object.fromEntries(Object.entries({
                email: this.email,
                role: this.role
                // eslint-disable-next-line no-unused-vars
            }).filter(([_, e]) => !!e));

            BackendApi
                .patch(process.env.VUE_APP_BACKEND_URL + '/api/users/' + this.formUser.id, payload)
                .then(() => {
                    this.updateUserDialog = false;
                    this.fetchUsers();
                    this.displaySnackbar('Benutzer erfolgreich aktualisiert')
                })
                .catch(this.handleAxiosFormError)
                .catch((error) => {
                    this.updateUserDialog = false;
                    if (error.response?.data.type === 'cannot-change-own-role') {
                        this.displaySnackbar('Die eigene Rolle lässt sich nicht ändern', 'error');
                    } else {
                        this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
                    }
                })
        },
        openDeleteUser(userId) {
            this.formUser = this.users.find(user => user.id === userId);
            this.deleteUserDialog = true;
        },
        deleteUser() {
            BackendApi.delete(process.env.VUE_APP_BACKEND_URL + '/api/users/' + this.formUser.id)
                .then(() => {
                    this.deleteUserDialog = false;
                    this.fetchUsers();
                    this.displaySnackbar('Benutzer erfolgreich gelöscht');
                })
                .catch((error) => {
                    this.deleteUserDialog = false;
                    if (error.response?.data.type === 'cannot-delete-self') {
                        this.displaySnackbar('Selber löschen nicht möglich', 'error');
                    } else {
                        this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
                    }
                })
        },
        displaySnackbar(text, color = 'success') {
            this.snackbarText = text;
            this.snackbarColor = color;
            this.snackbar = true;
        },
        resetErrorHook() {
            this.errorPasswordConfirmMsg = false;
        }
    },
    computed: {
        roleSelectItems() {
            // eslint-disable-next-line no-unused-vars
            return Object.entries(roles).map(([_, value]) => ({
                value: value,
                title: translateRole(value),
            }));
        }
    },
    watch: {
        deleteUserDialog(value) {
            if (!value) {
                this.formUser = null;
            }
        },
        createUserDialog(value) {
            if (!value) {
                this.email = null;
                this.password = null;
                this.passwordConfirm = null;
                this.role = null;
            }
        },
        updateUserDialog(value) {
            if (!value) {
                this.email = null;
                this.role = null;
            }
        }
    }
}
</script>
