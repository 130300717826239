<template>
    <v-container fluid class="pa-0 position-relative" v-if="liveRegatta">
        <v-row class="ma-0">
            <v-col class="pa-0">
                <MapComponent style="height: 500px; width: 100%" :lon="this.liveRegatta.longitude" :lat="this.liveRegatta.latitude" static></MapComponent>
            </v-col>
        </v-row>

        <v-container class="position-absolute container-center">
            <v-row>
                <v-col>
                    <div class="d-flex">
                        <div class="rounded-xl d-flex justify-center align-center play-button"
                             @click="$router.push('/watch?id=' + liveRegatta.id)">
                            <v-icon class="ml-2" size="50" icon="mdi-play-outline"></v-icon>
                        </div>
                        <div class="ml-4">
                            <div class="text-h4 text-grey-darken-4 font-weight-medium"> {{ liveRegatta.name }}</div>
                            <div class="text-grey-darken-4 font-weight-medium"> {{ convertDate(liveRegatta.start) }}
                                Uhr
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
    <v-container :class="liveRegatta? '' : 'mt-16'">
        <v-row>
            <v-col>
                <div @click="showFutureRegattas = !showFutureRegattas" class="text-h4 mt-8">
                    Bevorstehende Regatten
                    <v-icon class="text-h4" v-show="showFutureRegattas" icon="mdi-chevron-down"></v-icon>
                    <v-icon class="text-h4" v-show="!showFutureRegattas" icon="mdi-chevron-right"></v-icon>
                </div>
            </v-col>
        </v-row>

        <v-slide-y-transition v-show="showFutureRegattas">
            <v-row>
                <v-col v-for="regatta in futureRegattas" :key="regatta.id" sm="3" cols="12">
                    <v-card color="#1E1E1E"
                            @click="$router.push('/watch?id=' + regatta.id)">
                        <MapComponent style="height:150px" :lon="regatta.longitude" :lat="regatta.latitude" static></MapComponent>
                        <v-card-item>
                            <div class="text-h6">{{ regatta.name }}</div>
                            {{ convertDate(regatta.start) }} Uhr
                        </v-card-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-row>
            <v-col>
                <div @click="showPastRegattas = !showPastRegattas" class="text-h4 mt-8">
                    Vergangene Regatten
                    <v-icon class="text-h4" v-show="showPastRegattas" icon="mdi-chevron-down"></v-icon>
                    <v-icon class="text-h4" v-show="!showPastRegattas" icon="mdi-chevron-right"></v-icon>
                </div>
            </v-col>
        </v-row>

        <v-slide-y-transition v-show="showPastRegattas">
            <v-row>
                <v-col v-for="regatta in pastRegattas" :key="regatta.id" sm="3" cols="12">
                    <v-card color="#1E1E1E"
                            @click="$router.push('/watch?id=' + regatta.id)">
                        <ol-map
                            ref="maps"
                            :loadTilesWhileAnimating="true"
                            :loadTilesWhileInteracting="true"
                            style="height:150px"
                        >
                            <ol-view
                                :center="convert(regatta.longitude, regatta.latitude)"
                                :zoom="14"
                                projection="EPSG:3857"
                            />

                            <ol-tile-layer>
                                <ol-source-osm/>
                            </ol-tile-layer>
                        </ol-map>
                        <v-card-item>
                            <div class="text-h6">{{ regatta.name }}</div>
                            {{ convertDate(regatta.start) }} Uhr
                        </v-card-item>
                    </v-card>
                </v-col>
            </v-row>
        </v-slide-y-transition>
    </v-container>


    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import * as Projection from "ol/proj";
import MapComponent from '@/components/Map.vue';

export default {
    name: 'HomeView',
    components: {
        MapComponent
    },
    data: () => ({
        regattas: [],
        liveRegatta: null,

        showPastRegattas: true,
        showFutureRegattas: true,

        disabledInteractions: [
            'DragPan2',
            'PinchRotate2',
            'PinchZoom2',
            'KeyboardPan2',
            'KeyboardZoom2',
            'MouseWheelZoom2',
            'DragZoom2',
            'DragRotate2',
            'DoubleClickZoom2',
        ],

        snackbar: {
            show: false,
            color: 'error',
            text: ''
        }
    }),
    mounted() {
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/regatta')
            .then((response) => {
                this.regattas = response.data
            })
            .catch(() => {
                this.snackbar.show = true;
                this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server';
            });
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/regatta/live')
            .then((response) => {
                this.liveRegatta = response.data
            })
            .catch(() => {
                this.snackbar.show = true;
                this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server';
            });


        setTimeout(() => {
            this.$refs.maps?.forEach(this.disableInteractions);
            this.disableInteractions(this.$refs.map);
        }, 500);
    },
    methods: {
        disableInteractions(map) {
            // TODO: Fix this (Interactions still work)
            if (map) {
                map.map.interactions?.array_.forEach((interaction) => {
                    if (this.disabledInteractions.includes(Object.getPrototypeOf(interaction).constructor.name)) {
                        interaction.values_.active = false;
                    }
                })
            }
        },
        convert(lng, lat) {
            return Projection.fromLonLat([lng, lat]);
        },
        convertDate(dateString) {
            const date = new Date(dateString.replace('Z', ''));
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            return date.toLocaleString('de-DE', options)
        }
    },
    computed: {
        futureRegattas() {
            return this.regattas.filter(regatta => {
                return new Date(regatta.start.replace('Z', '')) > new Date();
            }).sort((a, b) => {
                return new Date(a.start).getTime() - new Date(b.start).getTime();
            })
        },
        pastRegattas() {
            return this.regattas.filter(regatta => {
                return new Date(regatta.start.replace('Z', '')) < new Date();
            }).sort((a, b) => {
                return new Date(b.start).getTime() - new Date(a.start).getTime();
            })
        }
    },
}

</script>

<style lang="scss" scoped>
.container-center {
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
}

.play-button {
    width: 75px;
    height: 75px;
    opacity: 50%;

    background-color: #2b2b2b;
}
</style>