// Styles
import '@mdi/font/css/materialdesignicons.css'
import './../styles/Vuetify.scss'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

const customTheme = {
  dark: true,
  colors: {
      background: '#2B2B2B',
      surface: '#2B2B2B',
      primary: '#82d620',
      'primary-darken-1': '#3700B3',
      secondary: '#03DAC6',
      'secondary-darken-1': '#018786',
      error: '#B00020',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
  }
};

export default createVuetify({
  theme: {
      defaultTheme: 'customTheme',
      themes: {
          customTheme,
      }
  },
  icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
          mdi,
      }
  },
})