<template>
    <div class="si-entry" ref="stats">
        <div class="si-entry-color-container">
            <div class="si-entry-color" ref="color"></div>
        </div>
        <div class="si-entry-title no-select" @click="this.toggle()">
            <span class="si-entry-name" ref="name">Schiffsname</span>
        </div>
        <ul class="si-stats">
            <li><span ref="speedname">Geschwindigkeit: </span><span id="si-stat-speed" ref="speed">{{ this.ship.speed }}</span>kn</li>
            <li><span ref="coursename">Kurs: </span><span id="si-stat-course" ref="course">{{ this.ship.bearing }}</span>°</li>
            <li v-if="isLoggedIn">
                <v-btn v-if="!this.ship.isDSQ" class="dsq" @click="confirmDisqualify = true" block color="red" :disabled="!this.activeRun">DSQ</v-btn>
                <v-btn v-else class="dsq" @click="confirmDisqualify = true" block color="green" :disabled="!this.activeRun">UN-DSQ</v-btn>
            </li>
        </ul>
    </div>

    <v-dialog
        v-model="confirmDisqualify"
        persistent
        max-width="800"
        >
        <v-card class="pa-3">
            <v-card-title class="text-h5">
            Soll das Schiff {{ ship?.name }} wirklich {{ ship?.isDSQ? "undisqualifiziert" : "disqualifiziert" }} werden?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="confirmDisqualify = false"
            >
                Abbrechen
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="confirmDisqualify = false; toggleDisqualified()"
            >
                Bestätigen
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.display = false"></v-icon>
        </template>
    </v-snackbar>

</template>

<script>
import BackendApi from '@/scripts/BackendApi';
import Ship from '@/scripts/ship.js';
import UserCheck from '@/scripts/UserCheck';


export default {
    mixins: [UserCheck],
    name: 'ShipInspectorEntry',
    emits: ['disqualified'],
    props: {
        speed: {
            type: Number,
            default() {
                return 0;
            }
        },

        course: {
            type: Number,
            default() {
                return 0;
            }
        },

        way: {
            type: Number,
            default() {
                return 0;
            }
        },

        dist: {
            type: Number,
            default() {
                return 0;
            }
        },

        ship: {
            type: Ship,
            default() {
                return null;
            }
        },
        activeRun: {
            type: [Object, null],  
            required: true
        }
    },
    data() {
        return {
            toggled: false,
            confirmDisqualify: false, 
            snackbar: {
                display: false,
                text: "",
                color: "success"
            }
        }
    },
    mounted() {
        this.setColor(this.ship.color);
        this.setName(this.ship.name);
    },
    methods: {
        toggle() {
            if (this.toggled) {
                this.$refs.stats.classList.remove('si-entry-opened');
                this.toggled = false;
            } else {
                this.$refs.stats.classList.add('si-entry-opened');
                this.toggled = true;
            }
        },

        setColor(color) {
            this.$refs.color.style.backgroundColor = color;
        },

        setName(name) {
            this.$refs.name.innerText = name;
        },

        setSpeed(speed) {
            this.$refs.speed.innerText = "" + speed;
        },

        setCourse(course) {
            this.$refs.course.innerText = "" + course;
        },

        async toggleDisqualified() {
            if (this.ship != null) {
                const toDSQ = !this.ship.isDSQ;
                const url = toDSQ ? 
                                process.env.VUE_APP_BACKEND_URL + "/api/runs/disqualify/" + this.activeRun.id :
                                process.env.VUE_APP_BACKEND_URL + "/api/runs/undisqualify/" + this.activeRun.id;
                await BackendApi.post(url, {
                    shipId: this.ship.id, 
                })
                .then((response) => {
                    this.$emit("disqualified", JSON.parse(response.data.disqualifiedShips)); 
                })
                .catch(() => {
                    this.displaySnackbar("Fehler beim Disqualifizieren", "error");
                });
            }
        },

        displaySnackbar(text, color) {
            this.snackbar.text = text;
            this.snackbar.color = color;
            this.snackbar.display = true;
        }
    }
}

</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

.si-entry {
    width: 100%;
    height: $sidebar-width-imploded;
    cursor: pointer;
    overflow: hidden;
    transition: $transition-medium;
}

.si-entry-color-container {
    position: relative;
    width: $sidebar-width-imploded;
    height: $sidebar-width-imploded;
    float: left;
}

.si-entry-title {
    position: relative;
    width: calc(100% - $sidebar-width-imploded);
    height: $sidebar-width-imploded;
    float: right;
}

.si-entry-name {
    position: absolute;
    left: 0;
    top: calc($sidebar-width-imploded * 0.5);
    transform: translateY(-50%);
    color: $color-text;
}

.si-entry-color {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 10px;
    width: 10px;
    border-radius: 20px;
    background-color: red;
    box-shadow: 0 0 10px black;
}

.si-stats {
    list-style: none;
    padding: 10px 10px 10px $sidebar-width-imploded;
    color: $color-text;
}

.si-entry-opened {
    display: block;
    height: auto;
    box-shadow: inset 0 0 40px rgba(0,0,0,0.2);
}

.si-entry:hover {
    box-shadow: inset 0 0 50px rgba(0,0,0,0.2);
}

.dsq {
    color: $color-gray;
}

</style>