<template>
    <div class="icon" ref="icon">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TextIcon',
    props: {
        msg: String,
        color: String,
        user: Boolean,
        usermanagement: Boolean,
        list: Boolean,
        menu: Boolean,
        addcirc: Boolean,
        logout: Boolean,
        play: Boolean,
        pause: Boolean
    },
    data() {
        return {
            icons: {
                user: "Person",
                usermanagement: "manage_accounts",
                list: "list",
                menu: "menu",
                logout: "logout",
                addcirc: "add_circle",
                play: "play_arrow",
                pause: "pause"
            }
        }
    },
    mounted() {

        /* More at: https://fonts.google.com/icons */

        if (this.color) {
            this.$refs.icon.style.color = this.color;
        }

        /* Enumeration over all attributes not possible... :( */
        var icon = "";
        if (this.user) {
            icon = this.icons.user;
        } else if (this.usermanagement) {
            icon = this.icons.usermanagement;
        } else if (this.list) {
            icon = this.icons.list;
        } else if (this.menu) {
            icon = this.icons.menu;
        } else if (this.logout) {
            icon = this.icons.logout;
        } else if (this.addcirc) {
            icon = this.icons.addcirc;
        } else if (this.play) {
            icon = this.icons.play;
        } else if (this.pause) {
            icon = this.icons.pause;
        }
        this.setIcon(icon);
    },
    methods: {
        setIcon(icon) {
            this.$refs.icon.innerHTML = icon;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/TextIcon.scss";
</style>