<template>
    <div class="about">
        <h1>This is an about page</h1>
    </div>
    <p>
        Lorem Ipsum
        <br />
        <br />
        test test
    </p>
</template>
