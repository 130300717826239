<template>
    <v-container class="mt-16">
        <v-row justify="center">
            <v-col cols="12" sm="8">
                <div class="text-h4 mb-8 mt-12 text-center">{{regattaName}}</div>
                    <v-card>
                        <v-row>
                            <v-col class="text-center">
                                <p class="text-h6 ">Läufe</p>
                            </v-col>
                        </v-row>
                    <v-table>
                        <thead>
                            <tr>
                                <th style="width:30%" class="text-left">
                                    Name
                                </th>
                                <th class="text-center">
                                    Datum und Uhrzeit
                                </th>
                                <th style="width:30%" class="text-right">
                                    Aktionen
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="run in runs"
                            :key="run.name"
                            >
                                <td>{{ run.name }}</td>
                                <td class="text-center">{{ displayStartEndTimeForRun(run) }}</td>
                                <td class="d-flex justify-end align-center">
                                    <v-icon v-if="run.end !== null" class="mr-2" icon="mdi-pencil" @click="openEditPositionsDialog(run)"></v-icon>
                                    <v-icon icon="mdi-delete" @click="openDeleteRun(run.id, run.name)"></v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card>
                    <v-row>
                        <v-col>
                        </v-col>
                        <v-col></v-col>
                        <v-col class="text-center">
                            <p class="text-h6 ">Schiffe</p>
                        </v-col>
                        <v-col></v-col>
                        <v-col class="text-center">
                            <v-btn color="primary" @click="openShipDialog(/* ship: */null)">Hinzufügen</v-btn>
                        </v-col>
                    </v-row>
                    <v-table>
                        <thead>
                            <tr>
                                <th style="width:40%" class="text-left">
                                    Name
                                </th>
                                <th class="text-center">
                                    Raspberry Pi
                                </th>
                                <th style="width:40%" class="text-right">
                                    Aktionen
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="ship in ships"
                                :key="ship.name"
                            >
                                <td>{{ ship.name }}</td>
                                <td class="text-center">{{ ship.rpi?.name ?? "" }}</td>
                                <td class="d-flex justify-end align-center">
                                    <v-icon class="mr-2" icon="mdi-pencil" @click="openShipDialog(ship)"></v-icon>
                                    <v-icon icon="mdi-delete" @click="openDeleteShip(ship.id, ship.name)"></v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8">
                <v-card>
                    <v-row>
                        <v-col>
                        </v-col>
                        <v-col></v-col>
                        <v-col class="text-center">
                            <p class="text-h6 ">Tonnen</p>
                        </v-col>
                        <v-col></v-col>
                        <v-col class="text-center">
                            <v-btn color="primary" @click="openBarrelDialog(null)">Hinzufügen</v-btn>
                        </v-col>
                    </v-row>
                    <v-table>
                        <thead>
                            <tr>
                                <th style="width:40%" class="text-left">
                                    Name
                                </th>
                                <th class="text-center">
                                    Raspberry Pi
                                </th>
                                <th style="width:40%" class="text-right">
                                    Aktionen
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="barrel in barrels"
                            :key="barrel.name"
                            >
                            <td>{{ barrel.name }}</td>
                            <td class="text-center">{{ barrel.rpi?.name ?? "Statische Tonne" }}</td>
                            <td class="d-flex justify-end align-center">
                                <v-icon class="mr-2" icon="mdi-pencil" @click="openBarrelDialog(barrel)"></v-icon>
                                <v-icon icon="mdi-delete" @click="openDeleteBarrel(barrel.id, barrel.name)"></v-icon>
                            </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-col>
        </v-row>

        <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarText }}
            <template v-slot:actions>
                <v-icon icon="mdi-close" @click="snackbar = false"></v-icon>
            </template>
        </v-snackbar>

        <v-dialog v-model="editPositionsDialog" max-width="800">
            <EditPositionsDialog 
                :regattaName="regattaName"
                :runId="selectedRunId"
                :closeAction="closeEditPositionsDialog"
                :successAction="closeEditPositionsAfterSave"
            /> 
        </v-dialog>

        <v-dialog v-model="changeShip.showDialog" max-width="800">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">Schiff {{ changeBarrel.edit? "updaten" : "hinzufügen" }}</div>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                <v-text-field
                                    v-model="changeShip.name"
                                    label="Name"
                                    :error-messages="formErrors.name"
                                    @update:model-value="resetError('name')"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                <v-select
                                    v-model="changeShip.rpi"
                                    :items="pies"
                                    item-title="name"
                                    item-value="name"
                                    label="Raspberry Pi"
                                    return-object
                                    single-line
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                    <v-color-picker
                                    v-model="changeShip.color"
                                    dot-size="25"
                                    hide-canvas
                                    hide-inputs
                                    hide-mode-switch
                                    hide-sliders
                                    mode="hexa"
                                    show-swatches
                                    swatches-max-height="200"
                                    width="100%"
                                    ></v-color-picker>
                            </v-col>
                            
                            <v-col cols="6" class="d-flex justify-start">
                                <v-btn @click="changeShip.showDialog = false">Abbrechen</v-btn>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn color="primary" @click="commitChangeShip()">{{ changeShip.edit? "Update" : "Hinzufügen" }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="changeBarrel.showDialog" max-width="800">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">Tonne {{ changeBarrel.edit? "updaten" : "hinzufügen" }}</div>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                <v-text-field
                                    v-model="changeBarrel.name"
                                    label="Name"
                                    :error-messages="formErrors.name"
                                    @update:model-value="resetError('name')"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!this.changeBarrel.edit">
                                <v-radio-group
                                    v-model="changeBarrel.type"
                                    inline
                                    >
                                    <v-radio
                                        label="Statische Tonne"
                                        value="static"
                                        class="mr-4"
                                    ></v-radio>
                                    <v-radio
                                        label="Dynamische Tonne"
                                        value="dynamic"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col v-if="changeBarrel.type === 'dynamic'" cols="12" class="d-flex justify-start">
                                <v-select
                                    v-model="changeBarrel.rpi"
                                    :items="pies"
                                    item-title="name"
                                    item-value="name"
                                    label="Raspberry Pi"
                                    return-object
                                    single-line
                                >
                                </v-select>
                            </v-col>
                            <template v-else>
                                <v-col cols="6" class="d-flex justify-start">
                                    <v-text-field
                                        v-model="changeBarrel.longitude"
                                        :label="changeBarrel.edit ? 'Neuer Längengrad' : 'Längengrad'"
                                        :error-messages="formErrors.longitude"
                                        @update:model-value="resetError('longitude')"
                                    ></v-text-field>
                                </v-col>    
                                <v-col cols="6" class="d-flex justify-start">
                                    <v-text-field
                                        v-model="changeBarrel.latitude"
                                        :label="changeBarrel.edit ? 'Neuer Breitengrad' : 'Breitengrad'"
                                        :error-messages="formErrors.latitude"
                                        @update:model-value="resetError('latitude')"
                                    ></v-text-field>
                                </v-col>
                                
                            </template>
                            
                            <v-col cols="6" class="d-flex justify-start">
                                <v-btn @click="changeBarrel.showDialog = false">Abbrechen</v-btn>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn color="primary" @click="commitChangeBarrel()">{{ changeBarrel.edit? "Update" : "Hinzufügen" }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteRunDialog" max-width="800">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">{{delRunName}} löschen?</div>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-btn @click="deleteRunDialog = false">Abbrechen</v-btn>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end">
                                        <v-btn color="primary" @click="deleteRun(delRunId)">Bestätigen</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="deleteShipDialog" max-width="800">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">{{delShipName}} löschen?</div>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-btn @click="deleteShipDialog = false">Abbrechen</v-btn>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end">
                                        <v-btn color="primary" @click="deleteShip(delShipId)">Bestätigen</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="deleteBarrelDialog" max-width="800">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">{{delBarrelName}} löschen?</div>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-btn @click="deleteBarrelDialog = false">Abbrechen</v-btn>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end">
                                        <v-btn color="primary" @click="deleteBarrel(delBarrelId)">Bestätigen</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar" :color="snackbarColor">
                    {{ snackbarText }}
                    <template v-slot:actions>
                        <v-icon icon="mdi-close" @click="snackbar = false"></v-icon>
                    </template>
                </v-snackbar>
    </v-container>
</template>
<script>

import BackendApi from '@/scripts/BackendApi';
import router from '@/router/index';
import EditPositionsDialog from '@/components/EditPositionsDialog.vue';
import form from '@/scripts/Form';

export default {
    mixins: [form], 
    data: () => ({
        changeShip: {
            rpi: null,
            name: null,
            color: null, 
            id: null, 
            showDialog: false, 
            edit: false, // whether to edit or create a new ship
        }, 
        changeBarrel: {
            type: 'static', 
            rpi: null,
            name: null,
            id: null, 
            showDialog: false, 
            edit: false, // whether to edit or create a new barrel
            longitude: "", 
            latitude: "",
        }, 
        runName: null,
        createRunDialog: false,
        deleteRunDialog: false,
        deleteShipDialog: false,
        deleteBarrelDialog: false,
        delRunId: null,
        delShipId: null,
        delBarrelId: null,
        delRunName: null,
        delShipName: null,
        delBarrelName: null,
        editPositionsDialog: false,
        Regatta: [],
        runtitel: [],
        runs: [],
        ships: [],
        barrels: [],
        pies: [],
        select: null,
        snackbar: false,
        snackbarText: null,
        snackbarColor: null,
    }),
    computed: {
        regattaName() {
            return this.$route.query.regattaName;
        },
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            var id = this.$route.query.id;
            try {
                this.runs = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + "/api/runs/" + id))?.data;
                this.ships = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + "/api/ships/regatten/" + id))?.data;
                this.barrels = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + "/api/barrels/regatten/" + id))?.data;
                this.pies = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + "/api/rpis"))?.data;
            } catch {
                this.displaySnackbar("Fehler bei der Kommunikation mit dem Server", "error");
            }
        },
        openShipDialog(ship) {
            if (ship === null) { // create new ship
                this.changeShip = {
                    rpi: null,
                    name: "",
                    color: "", 
                    id: null, 
                    showDialog: true, 
                    edit: false, 
                }
            } else { // edit existing ship
                this.changeShip = {
                    rpi: ship.rpi,
                    name: ship.name,
                    color: ship.color, 
                    id: ship.id, 
                    showDialog: true, 
                    edit: true,
                }
            }
        }, 
        openBarrelDialog(barrel) {
            const isStatic = barrel?.rpi === null;
            const type = isStatic ? 'static' : 'dynamic';
            if (barrel === null) { // create new barrel
                this.changeBarrel = {
                    ...this.changeBarrel, 
                    rpi: null,
                    name: "",
                    id: null, 
                    showDialog: true, 
                    edit: false, 
                    type: type,
                }
            } else { // edit existing barrel
                this.changeBarrel = {
                    ...this.changeBarrel,
                    rpi: barrel.rpi,
                    name: barrel.name,
                    id: barrel.id, 
                    showDialog: true, 
                    edit: true,
                    type: type,
                    longitude: "", 
                    latitude: "",
                }
            }
        }, 
        openAssignBarrel(ship) {
            this.barrel = ship;
            this.assignBarrelDialog = true;
        },
        openAddBarrel() {
            this.addBarrelDialog = true;
        },
        openCreateRun() {
            this.createRunDialog = true;
        },
        openDeleteRun(id, name) {
            this.delRunId = id;
            this.delRunName = name;
            this.deleteRunDialog = true;
        },
        openDeleteShip(id, name) {
            this.delShipId = id;
            this.delShipName = name;
            this.deleteShipDialog = true;
        },
        openDeleteBarrel(id, name) {
            this.delBarrelId = id;
            this.delBarrelName = name;
            this.deleteBarrelDialog = true;
        },
        openEditPositionsDialog(run) {
            this.editPositionsDialog = true;
            this.selectedRunId = run.id;
        },
        closeEditPositionsDialog() {
            this.editPositionsDialog = false;
        },
        closeEditPositionsAfterSave() {
            this.displaySnackbar("Positionen gespeichert");
            this.closeEditPositionsDialog();
        },
        deleteRun(id) {
            BackendApi
                .delete(process.env.VUE_APP_BACKEND_URL + "/api/runs/" + id)
                .then(() => {
                    this.fetchData();
                    this.deleteRunDialog = false;
                    this.displaySnackbar(this.delRunName + ' wurde entfernt.');
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar("Fehler beim Löschen des Laufes", "error");
                })
        },
        routeToEditRunView(Id) {
            router.push("/lauf-edit?id=" + Id);
        },

        // ======= Change, delete and create ships =======
        commitChangeShip() {
            if (this.changeShip.edit) {
                this.updateShip(this.changeShip.rpi, this.changeShip.name, this.changeShip.color ?? "#ffffff", this.changeShip.id);
            } else {
                this.createShip(this.changeShip.rpi, this.changeShip.name, this.changeShip.color);
            }
        },
        updateShip(rpi, name, color, id){
            BackendApi
                .patch(process.env.VUE_APP_BACKEND_URL + "/api/ships/" + id, {
                rpiId: rpi?.id,
                name: name,
                color: color, 
            })
                .then(() => {
                    this.fetchData();
                    this.changeShip.showDialog = false;
                    this.displaySnackbar(name + ' wurde ' + rpi.name + ' geupdated.');
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar("Fehler Updaten des Schiffes", "error");
                })
        },
        createShip(rpi, name, color){
            BackendApi
                .post(process.env.VUE_APP_BACKEND_URL + '/api/ships', {
                    rpiId: rpi?.id,
                    name: name,
                    color: color, 
                    regattaId: parseInt(this.$route.query.id),
                })
                .then(() => {
                    this.fetchData();
                    this.changeShip.showDialog = false;
                    this.displaySnackbar(name + ' wurde hinzugefügt.');
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar("Fehler Updaten der Tonne", "error");
                })
        },
        deleteShip(id) {
            BackendApi
                .delete(process.env.VUE_APP_BACKEND_URL + "/api/ships/" + id)
                .then(() => {
                    this.fetchData();
                    this.deleteShipDialog = false;
                    this.displaySnackbar(this.delShipName + ' wurde entfernt.');
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar("Fehler beim Hinzufügen des Schiffes", "error");
                })
        }, 
        // ======= Change and create barrel =======
        commitChangeBarrel() {
            if (this.changeBarrel.edit) {
                this.updateBarrel();
            } else {
                this.createBarrel();
            }
        },
        updateBarrel(){
            if (this.changeBarrel.type === 'dynamic') {
                BackendApi
                    .patch(process.env.VUE_APP_BACKEND_URL + "/api/barrels/" + this.changeBarrel.id, {
                    rpiId: this.changeBarrel.rpi?.id,
                    name: this.changeBarrel.name,
                    regattaId: parseInt(this.$route.query.id),
                })
                    .then(() => {
                        this.fetchData();
                        this.changeBarrel.showDialog = false;
                        this.displaySnackbar(this.changeBarrel.name + ' wurde geupdated.');
                    })
                    .catch(this.handleAxiosFormError)
            } else {
                // it's only possible to change both coordinates at the same time
                if (this.changeBarrel.latitude !== this.changeBarrel.longitude && (this.changeBarrel.latitude === '' || this.changeBarrel.longitude === '')) {
                    this.displaySnackbar("Bitte geben Sie beide Koordinaten an.", "error");
                    return;
                }

                BackendApi
                    .patch(process.env.VUE_APP_BACKEND_URL + "/api/barrels/static-barrel/" + this.changeBarrel.id, {
                    name: this.changeBarrel.name,
                    latitude: this.changeBarrel.latitude,
                    longitude: this.changeBarrel.longitude,
                })
                    .then(() => {
                        this.fetchData();
                        this.changeBarrel.showDialog = false;
                        this.displaySnackbar(this.changeBarrel.name + ' wurde geupdated.');
                    })
                    .catch(this.handleAxiosFormError)
            }
        },
        createBarrel(){
            if (this.changeBarrel.type === 'static') {
                BackendApi
                    .post(process.env.VUE_APP_BACKEND_URL + '/api/barrels/static-barrel', {
                        name: this.changeBarrel.name,
                        latitude: this.changeBarrel.latitude,
                        longitude: this.changeBarrel.longitude,
                        regattaId: parseInt(this.$route.query.id),
                    })
                    .then(() => {
                        this.fetchData();
                        this.changeBarrel.showDialog = false;
                        this.displaySnackbar(this.changeBarrel.name + ' wurde  hinzugefügt.');
                    })
                    .catch(this.handleAxiosFormError)
            } else {
                BackendApi
                    .post(process.env.VUE_APP_BACKEND_URL + '/api/barrels', {
                        rpiId: this.changeBarrel.rpi?.id,
                        name: this.changeBarrel.name,
                        regattaId: parseInt(this.$route.query.id),
                    })
                    .then(() => {
                        this.fetchData();
                        this.changeBarrel.showDialog = false;
                        this.displaySnackbar(this.changeBarrel.name + ' wurde  hinzugefügt.');
                    })
                    .catch(this.handleAxiosFormError)
            }
        },
        deleteBarrel(id) {
            BackendApi
                .delete(process.env.VUE_APP_BACKEND_URL + "/api/barrels/" + id)
                .then(() => {
                    this.fetchData();
                    this.displaySnackbar(this.delBarrelName + ' wurde entfernt.');
                    this.deleteBarrelDialog = false;
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.displaySnackbar("Fehler beim Hinzufügen der Tonne", "error");
                })
        },

        displaySnackbar(text, color = 'success') {
            this.snackbarText = text;
            this.snackbarColor = color;
            this.snackbar = true;
        },

        formatTime(date) {
            if (date == null) {
                return "";
            }
            const d = new Date(date);
            let dateString = d.toLocaleString("de-DE", { timeZone: "Europe/Berlin", minimumIntegerDigits: 2 })
            
            // remove date (return only time) and seconds 
            return dateString.substring(dateString.indexOf(",") + 2).substring(0, 5);
        },

        formatDate(date) {
            if (date == null) {
                return "";
            }
            const d = new Date(date);
            let dateString = d.toLocaleString("de-DE", { timeZone: "Europe/Berlin", minimumIntegerDigits: 2 })
            
            // remove time (return only date) 
            return dateString.substring(0, dateString.indexOf(","));
        },

        displayStartEndTimeForRun(run) {
            let resultString = ""; 
            if (run.start != null) {
                resultString += this.formatDate(run.start) + ": ";
                resultString += this.formatTime(run.start);
            } 
            if (run.end != null) {
                resultString += " - " + this.formatTime(run.end);
            } else {
                resultString += " - ";
            }
            return resultString;
        },

    }, 
    components: {
        EditPositionsDialog
    }

}
</script>

<style>

</style>