<template>
    <v-container class="d-flex fill-height">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" xl="4">
                <div class="text-h4 mb-6">Passwort vergessen?</div>
                <v-text-field
                    label="E-Mail"
                    v-model="email"
                    :error-messages="formErrors.email"
                    @update:modelValue="resetError('email')"
                ></v-text-field>
                <v-btn class="mt-4" color="primary" @click="resetPassword">Passwort zurücksetzen</v-btn>
                <v-alert class="mt-5" type="success" density="comfortable" variant="tonal" v-if="successMsg">
                    Wenn die eingegebene E-Mail-Adresse {{ email }} einem Benutzer
                    zugeordnet ist, senden wir Dir einen Link zum Zurücksetzen Deines Passworts.
                </v-alert>
            </v-col>
        </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import form from './../scripts/Form';

export default {
    name: 'ForgotPasswordView',
    mixins: [form],
    data: () => ({
        email: null,
        formErrors: {},
        successMsg: false,
        snackbar: {
            show: false,
            color: 'error',
            text: ''
        }
    }),
    methods: {
        /**
         * Sends a post request to the backend to reset the password by given email
         */
        resetPassword() {
            axios.post(process.env.VUE_APP_BACKEND_URL + '/api/auth/reset', {
                email: this.email
            }).then(() => {
                this.successMsg = true;
            }).catch(this.handleAxiosFormError)
            .catch(() => {
                this.snackbar = {
                    show: true,
                    color: 'error',
                    text: 'Fehler bei der Verbindung zum Server.'
                };
            });
        },

        /**
         * Resets error messages
         */
        resetErrorHook() {
            this.formErrors = {};
            this.successMsg = false;
        }
    }
}
</script>
