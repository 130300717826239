<template>
    <v-app>
        <v-main class="d-flex flex-column flex-nowrap">
            <router-view></router-view>
            <navigation-bar></navigation-bar>
        </v-main>
    </v-app>

</template>

<script>
import NavigationBar from '@/components/NavigationBar';
import bus from './scripts/Bus';

export default {
    name: 'App',
    components: {
        NavigationBar
    },
    mounted() {
        bus.on('router.push', (payload) => {
            this.$router.push(payload);
        })
    }
}

</script>

<style lang="scss" scoped>
@import 'styles/App.scss';
</style>
