<template>
<div id="sidebar" ref="sidebar"> 
    <SidebarGrabble :sidebar="this" ></SidebarGrabble>
    <slot></slot>
</div>
</template>

<script>
import SidebarGrabble from '@/components/SidebarGrabble.vue';

export default {
    name: 'SidebarComponent',
    components: {
        SidebarGrabble
    },
    methods: {
        toggleSidebar() {
            let sb = this.$refs.sidebar;
            if (sb.classList.contains('sidebar-hidden')) {
                this.$refs.sidebar.classList.remove('sidebar-hidden');
            } else {
                this.$refs.sidebar.classList.add('sidebar-hidden');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

#sidebar {
    position: absolute;
    left: calc(100% - $sidebar-width-extended);
    width: $sidebar-width-extended;
    height: 100%;
    background: $color-gray;
    box-shadow: $shadow-medium;
    transition: 0.3s ease-in;
}

.sidebar-hidden {
    transform: translateX(calc(100% - $sidebar-width-imploded));
}
</style>