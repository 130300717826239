import MapIcon from '@/scripts/mapicon.js';
import Coordinates from '@/scripts/coordinates.js';

export default class Ship {

    constructor(id, name, color) {
        this.id = id;
        this.name = name;
        this.color = color;
        this.placement = 0;
        this.isDSQ = false;
        this.coordinates = new Coordinates(0, 0);
        this.speed = 0;
        this.bearing = 0;
        this.mapIcon = new MapIcon('/ship.svg', this.coordinates, 0.15);
        this.mapIcon.setColor(color);
        this.bearingMapIconSource = 'data:image/svg+xml;utf8,' + '<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg"><path stroke="' 
                                    + this.color.replace('#', '%23') + '" stroke-width="10" d="M14.82,30.82L24,21.66L33.18,30.41L36,28L24,16L12,28L14.82,30.82Z"/></svg>'
        this.bearingMapIcon = new MapIcon(this.bearingMapIconSource, 
                                this.coordinates, 0.3, [0.5, 1.8]);
    }

    setCoordinates(coordinates) {
        this.coordinates = coordinates;
        this.mapIcon.setCoordinates(coordinates);
        this.bearingMapIcon.setCoordinates(coordinates);
    }

    setBearing(bearing) {
        this.bearing = bearing;
        this.bearingMapIcon.setIcon(this.bearingMapIconSource, this.color, 1.0, bearing);
    }

    setDisqualified(isDSQ) {
        this.isDSQ = isDSQ;
        if (isDSQ) {
            this.mapIcon.setColor(MapIcon.IconColor.disqualified);
        } else {
            this.mapIcon.setColor(this.color);
        }
    }

}