import axios from 'axios';
import bus from './Bus';

// Interceptor to check for expired tokens in case of error
// TODO: Dont apply interceptor globally
axios.interceptors.response.use(undefined, async (error) => {
    // Only try to refresh jwt token if error is token expired and the request is not a refresh request already
    if (error.response?.data?.type !== 'token-expired' || error.config.url.includes('auth/refresh')) {
        // If not token expired but still 401 redirect to home
        if (error.response.status === 401) {
            bus.emit('router.push', {name: 'home'})
        }

        return Promise.reject(error);
    }

    // Try to refresh the jwt token and retry the original request
    return refresh().then((token) => {
        return axios.request({
            method: error.config.method,
            url: error.config.url,
            headers: {
                Authorization: `Bearer ${token}`
            },  
            data: JSON.parse(error.config.data) ?? {},
        });
    });
});

/**
 * Tries to refresh the jwt token via the refresh token
 * If not possible redirects to the login and removes current tokens from localstorage
 * @returns {Promise<*>}
 */
async function refresh() {
    try {
        const response = await axios.post(process.env.VUE_APP_BACKEND_URL + '/api/auth/refresh', {
            refresh_token: window.localStorage.getItem('refresh_token')
        });

        if (response.data?.access_token && response.data?.refresh_token) {
            localStorage.setItem('access_token', response.data.access_token);
            bus.emit('access-token.set', response.data.access_token);

            localStorage.setItem('refresh_token', response.data.refresh_token);
            bus.emit('refresh-token.set', response.data.refresh_token);

            return response.data.access_token;
        }
    } catch (error) {
        localStorage.setItem('access_token', '');
        bus.emit('access-token.set', '');

        localStorage.setItem('refresh_token', '');
        bus.emit('refresh-token.set', '');

        bus.emit('router.push', {name: 'login'})
    }
}

const BackendApi = class BackendApi {
    /**
     * Wrapper for authorized get requests to the backend
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async get(url, payload = null) {
        return this.request('GET', url, payload);
    }

    /**
     * Wrapper for authorized post requests to the backend
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async post(url, payload = null) {
        return this.request('POST', url, payload);
    }

    /**
     * Wrapper for authorized patch requests to the backend
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async patch(url, payload = null) {
        return this.request('PATCH', url, payload);
    }

    /**
     * Wrapper for authorized delete requests to the backend
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async delete(url, payload = null) {
        return this.request('DELETE', url, payload);
    }

    /**
     * Methods to perform authorized requests to the Backend
     * Automatically implements jwt token refresh on expired token
     * @param method
     * @param url
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async request(method, url, payload) {
        return axios.request({
            method: method,
            url: url,
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
            },
            data: payload,
        });
    }
}

export default BackendApi;
