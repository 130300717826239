import bus from './Bus';

export default {
    data: () => ({
        neededRoles: [],
        accessTokenValue: localStorage.getItem('access_token') || '',
        refreshTokenValue: localStorage.getItem('refresh_token') || '',
    }),
    created() {
        if (this.neededRoles.length > 0) {
            this.checkUserAccess(this.neededRoles);
        }

        bus.on('access-token.set', (token) => {
            this.accessTokenValue = token;
        });

        bus.on('refresh-token.set', (token) => {
            this.refreshTokenValue = token;
        });
    },
    methods: {
        checkUserAccess(neededRoles) {
            if (this.isLoggedIn) {
                if (!this.hasRoles(neededRoles)) {
                    this.$router.push({name: 'home'})
                }
            } else {
                this.$router.push({name: 'login'})
            }
        },
        hasRoles(roles) {
            return roles.includes(JSON.parse(atob(this.accessToken.split('.')[1])).role);
        }
    },
    computed: {
        accessToken: {
            get() {
                return this.accessTokenValue;
            },
            set(token) {
                this.accessTokenValue = token;
                bus.emit('access-token.set', token);
                localStorage.setItem('access_token', token);
            }
        },
        refreshToken: {
            get() {
                return this.refreshTokenValue;
            },
            set(token) {
                this.refreshTokenValue = token;
                bus.emit('refresh-token.set', token);
                localStorage.setItem('refresh_token', token);
            }
        },
        isLoggedIn() {
            return !!this.accessToken;
        }
    }
}