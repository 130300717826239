export default {
    data: () => ({
        formErrors: {},
    }),
    methods: {
        resetError(fieldName) {
            delete this.formErrors[fieldName];

            this.resetErrorHook();
        },
        resetErrorHook() {
        },
        handleAxiosFormError(error) {
            if (error.response?.data.message !== undefined) {
                error.response.data.message.forEach(msg => {
                    if (msg.property && msg.constraints) {
                        this.formErrors[msg.property] = Object.values(msg.constraints)[0];
                    }
                });
            } else {
                throw error;
            }
        }
    }
}