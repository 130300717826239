<template>
    <div id="si-container" ref="container"  v-if="activeRun">
        <ShipInspectorEntry v-for="ship in this.watchView.ships" :ship="ship" :key="ship" :activeRun="activeRun" @disqualified="propagateDisqualified"></ShipInspectorEntry>
    </div>
</template>

<script>
//import Ship from '@/scripts/ship';
import ShipInspectorEntry from '../components/ShipInspectorEntry.vue';
import WatchView from '../views/WatchView.vue';

export default {
    name: 'ShipInspector',
    components: {
        ShipInspectorEntry,
    },
    props: {
        watchView: {
            type: WatchView,
            default() {
                return null;
            }
        },
        activeRun: {
            type: [Object, null],  
            required: true
        }
    },
    methods: {

        // eslint-disable-next-line
        resizeInspector(e) {
            let height = this.$refs.container.parentElement.offsetHeight - this.$refs.container.offsetTop;
            this.$refs.container.style.height = height + "px";
        },

        sortShipsByDistanceToGoal() {
            // TODO...
        },
        propagateDisqualified(disqualifiedShips) {
            this.$emit('disqualified', disqualifiedShips);
        }
    }
}
</script>

<style lang="scss" scoped>
#si-container {
    position: relative;
    width: 100%;
    //overflow: scroll;
}
</style>