<template>
    <div class="map" ref="mapcontainer"></div>
</template>

<script>
// npm install vue3-openlayers

import {Map, View} from 'ol';
import Tile from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import * as Projection from 'ol/proj';
import OSM from 'ol/source/OSM';
//import {transformExtent} from 'ol/proj';


export default {
    name: 'MapComponent',
    props: {
        msg: String,
        target: String,
        lon: Number,
        lat: Number,
        deviation: {
            type: Number,
            default() {
                return 0.05;
            }
        },
        static: Boolean
    },
    data() {
        return {
            icons: [],
            map: null,
            vectorLayer: null,
            vectorSource: null
        }
    },
    components: {
        
    },
    mounted() {

        this.vectorSource = new VectorSource({
            features: [],
        });

        this.vectorLayer = new VectorLayer({
            source: this.vectorSource,
        });

        this.map = new Map({
            layers: [
                new Tile({
                    source: new OSM(),
                }),
                this.vectorLayer
            ],
            view: new View({
                center: Projection.fromLonLat([this.lon, this.lat]),
                zoom: 16,
                //maxZoom: 20,
                //minZoom: 3,
                //extent: transformExtent([this.lon - this.deviation, this.lat - this.deviation, this.lon + this.deviation, this.lat + this.deviation], 'EPSG:4326', 'EPSG:3857')
            }),
            controls: [
                
            ],
            target: this.$refs.mapcontainer
        });

        if (this.static) {
            this.map.getInteractions().forEach(function(interaction) {
                interaction.setActive(false);
            });
        }
    },
    methods: {
        addIcon(mapIcon) {
            this.icons.push(mapIcon);
            this.vectorSource.addFeature(mapIcon.iconFeature);
        },

        removeIcon(mapIcon) {
            for (let i = 0; i < this.icons.length; ++i) {
                if (this.icons[i] === mapIcon) {
                    this.vectorSource.removeFeature(this.icons[i].iconFeature);
                    this.ships.splice(i, 1);
                    break;
                }
            }
        },

        fitZoom() {
            let extent = this.vectorLayer.getSource().getExtent();
            this.map.getView().fit(extent);
            this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        },

        setCenter(lon, lat) {
            this.map.getView().setCenter(Projection.fromLonLat([lon, lat]));
        }
    }
}

</script>

<style lang="scss" scoped>
@import '../styles/Map.scss';
</style>