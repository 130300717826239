<template>
    <v-container class="mt-16">
        <v-row justify="center">
            <v-col cols="12" sm="8">
                <div class="text-h4 mb-8 mt-12 text-center">Regattenübersicht</div>
                <v-card
                    class="mx-auto"
                >
                    <v-btn v-if="isLoggedIn" class="mt-4" color="primary" @click="openCreateRegatta()">
                        Erstellen
                    </v-btn>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left">Regatta</th>
                                <th class="text-left">Datum</th>
                                <th class="text-right">Aktionen</th>
                            </tr>     
                        </thead>
                        <tbody>
                            <tr
                                v-for="regatta in regatten"
                                :key="regatta.name"
                            >
                                <td>{{ regatta.name }}</td>
                                <td>{{ formatDate(regatta.start) }}</td>
                                <td class="d-flex justify-end align-center">
                                    <v-icon class="mr-2" icon="mdi-play" @click="routeToWatch(regatta.id)"></v-icon>
                                    <v-icon v-if="isLoggedIn" class="mr-2" icon="mdi-pencil" @click="routeToRegattaview(regatta.id, regatta.name)"></v-icon>
                                    <v-icon v-if="isLoggedIn" icon="mdi-delete" @click="openDeleteRegatta(regatta)"></v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>

                <v-snackbar v-model="snackbar" :color="snackbarColor">
                    {{ snackbarText }}
                    <template v-slot:actions>
                        <v-icon icon="mdi-close" @click="snackbar = false"></v-icon>
                    </template>
                </v-snackbar>

                <v-dialog v-if="isLoggedIn" v-model="createRegattaDialog" max-width="800">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">Regatta hinzufügen:</div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-text-field
                                            v-model="regattaName"
                                            label="Regatta-Name"  
                                            :error-messages="formErrors.name"
                                            @update:model-value="resetError('name')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-text-field
                                            type="date"
                                            v-model="date"
                                            label="Datum"  
                                            :error-messages="formErrors.start"
                                            @update:model-value="resetError('start')"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-row class="mx-5">
                            <v-col cols="6" class="d-flex justify-start">
                                <v-text-field
                                    v-model="regattaPosition.longitude"
                                    label="Längengrad"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-start">
                                <v-text-field
                                    v-model="regattaPosition.latitude"
                                    label="Breitengrad"  
                                ></v-text-field>
                            </v-col>
                        </v-row>                    
                        <v-row class="ma-0">
                            <v-col class="pa-0">
                                <ol-map
                                    ref="map"
                                    :loadTilesWhileAnimating="true"
                                    :loadTilesWhileInteracting="true"
                                    style="height:500px"
                                >
                                    <ol-view
                                        :center="convert(regattaPosition.longitude, regattaPosition.latitude)"
                                        projection="EPSG:3857"
                                        :zoom="15"
                                    />
                                    <ol-tile-layer>
                                        <ol-source-osm/>
                                    </ol-tile-layer>
                                </ol-map>
                            </v-col>
                        </v-row>
                        <v-row class="ma-5">
                            <v-col cols="6" class="d-flex justify-start">
                                <v-btn @click="createRegattaDialog = false">Abbrechen</v-btn>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end">
                                <v-btn color="primary" @click="createRegatta()">Erstellen</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="deleteRegattaDialog" max-width="800">
                    <v-card>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-start">
                                        <div class="text-h5">{{delRegatta.name}} löschen?</div>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-start">
                                        <v-btn @click="deleteRegattaDialog = false">Abbrechen</v-btn>
                                    </v-col>
                                    <v-col cols="6" class="d-flex justify-end">
                                        <v-btn color="primary" @click="deleteRegatta(delRegatta.id, delRegatta.name)">Bestätigen</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import BackendApi from '@/scripts/BackendApi';
import router from '@/router/index';
import UserCheck from '@/scripts/UserCheck';
import {roles} from '@/types/Role';
import * as Projection from "ol/proj";
import form from "@/scripts/Form";


    export default {
        mixins: [UserCheck, form],
        data: () => ({
            date: null,
            regattaName: null,
            regattaPosition: {
                longitude: '9.961410616730456',
                latitude: '51.72692694954814'
            },
            regatten: [],
            delRegatta: null,
            createRegattaDialog: false,
            deleteRegattaDialog: false,
            roles: roles,
            snackbar: false,
            snackbarText: null,
            snackbarColor: null,
        }),

        mounted() {
            this.fetchRegattas();
        },

        methods:{
            async fetchRegattas() {
                try {
                    this.regatten = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/regatta'))?.data;
                } catch {
                    this.displaySnackbar("Fehler bei der Kommunikation mit dem Server.", "error")
                }
            },
            
            openCreateRegatta() {
                this.date = null;
                this.regattaName = null;
                this.createRegattaDialog = true;
            },
            
            createRegatta() {
                const position = this.getLonLatCoordinates();
                BackendApi
                .post(process.env.VUE_APP_BACKEND_URL + '/api/regatta', {
                    name: this.regattaName, 
                    start: this.date ? new Date(this.date).toISOString() : null,
                    longitude: position[0] + "",
                    latitude: position[1] + "",
                })
                .then(() => {
                    this.fetchRegattas();
                    this.createRegattaDialog = false;
                    this.displaySnackbar(name + ' hinzugefügt');
                })
                .catch(this.handleAxiosFormError)
            },
            
            displaySnackbar(text, color = 'success') {
                this.snackbarText = text;
                this.snackbarColor = color;
                this.snackbar = true;
            },
            convert(lng, lat) {
                return Projection.fromLonLat([lng, lat]);
            },
            getLonLatCoordinates() {
                const center = this.$refs.map.map.getView().getCenter(); 
                return Projection.toLonLat(center);
            },  
            
            openUpdateSchiff(){
                
            },
            openDeleteRegatta(regatta){
                this.deleteRegattaDialog = true;
                this.delRegatta = regatta;
            },
            
            deleteRegatta(Id, name){
                BackendApi
                .delete(process.env.VUE_APP_BACKEND_URL + '/api/regatta/' + Id)
                .then(() => {
                    this.fetchRegattas();
                    this.deleteRegattaDialog = false;
                    this.displaySnackbar(name + ' ist gelöscht');
                })
                .catch(this.handleAxiosFormError)
            },

            routeToRegattaview(regattaId, regattaName){
                router.push('/regatta-view?id='+ regattaId +'&regattaName='+ regattaName);
            },

            routeToWatch(regattaId){
                router.push('/watch?id='+regattaId);
            }, 

            formatDate(date) {
                return new Date(date).toLocaleDateString();
            }
            
        }
            
    }
</script>

<style>

</style>