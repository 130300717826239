import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import WatchView from '../views/WatchView.vue'
import ForgotPasswordView from "@/views/ForgotPasswordView";
import ResetPasswordView from "@/views/ResetPasswordView";
import UserManagementView from "@/views/UserManagementView";
import RegattaOverviewView from "@/views/RegattaOverviewView";
import RegattaviewView from "@/views/RegattaviewView";
import TestWatchView from "@/views/TestWatchView.vue";
import ImpressumView from "@/views/ImpressumView.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/rpi-management',
    name: 'rpiManagement',
    component: () => import(/* webpackChunkName: "rpiManagement" */ '../views/RpiManagementView.vue')
  },
  {
    path: '/watch',
    name: 'watch',
    component: WatchView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView
  },
  {
    path: '/user-management',
    name: 'userManagement',
    component: UserManagementView
  },
  {
    path: '/test-watch/:regatta',
    name: 'testWatchView',
    component: TestWatchView
  },
  {
    path: '/regatta-overview',
    name: 'regatta-overview',
    component: RegattaOverviewView
  },
  {
    path: '/regatta-view',
    name: 'regatta-view',
    component: RegattaviewView
  },
  {
      path: '/impressum',
      name: 'impressumView',
      component: ImpressumView
  }
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
