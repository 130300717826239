<template>
    <v-container class="d-flex fill-height">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" xl="4">
                <div class="text-h4 mb-6">Passwort zurücksetzen</div>
                <v-text-field
                    type="password"
                    label="Passwort"
                    v-model="password"
                    :error-messages="formErrors.password"
                    @update:modelValue="resetError('password')"
                ></v-text-field>
                <v-text-field
                    type="password"
                    label="Passwort bestätigen"
                    v-model="passwordConfirm"
                    @update:modelValue="resetError('passwordConfirm')"
                ></v-text-field>
                <v-btn class="mt-4" color="primary" @click="changePassword">Passwort ändern</v-btn>
                <v-alert class="mt-5" type="success" density="comfortable" variant="tonal" v-if="successMsg">
                    Passwort erfolgreich geändert.
                    <router-link :to="{ name: 'login' }"> Zurück zum Login</router-link>
                </v-alert>
                <v-alert class="mt-5" type="error" density="comfortable" variant="tonal" v-if="errorPasswordConfirmMsg">
                    Passwörter stimmen nicht über ein
                </v-alert>
                <v-alert class="mt-5" type="error" density="comfortable" variant="tonal" v-if="errorResetExpiredMsg">
                    Passwort ändern Link abgelaufen.
                    <router-link :to="{ name: 'forgotPassword' }"> Neuen Link anfordern</router-link>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import axios from 'axios';
import form from './../scripts/Form';

export default {
    name: 'ResetPasswordView',
    mixins: [form],
    data: () => ({
        password: null,
        passwordConfirm: null,
        resetToken: null,
        successMsg: false,
        errorPasswordConfirmMsg: false,
        errorResetExpiredMsg: false,
        snackbar: {
            show: false,
            color: 'error',
            text: ''
        }
    }),
    mounted() {
        const params = new URLSearchParams(window.location.search);
        this.resetToken = params.get('token');

        if (!this.resetToken) {
            this.$router.push({name: 'forgotPassword'});
        }
    },
    methods: {
        /**
         * Checks if both passwords are equal and then sends a post request to the backend to change the password
         */
        async changePassword() {
            this.successMsg = false;

            if (this.password !== this.passwordConfirm) {
                this.password = null;
                this.passwordConfirm = null;
                this.errorPasswordConfirmMsg = true;

                return;
            }

            axios.post(process.env.VUE_APP_BACKEND_URL + '/api/auth/passwordChange', {
                resetToken: this.resetToken,
                password: this.password
            })
                .then(() => {
                    this.successMsg = true;
                })
                .catch(this.handleAxiosFormError)
                .catch((error) => {
                    if (error.response?.data.type === 'reset-token-expired') {
                        this.errorResetExpiredMsg = true;
                    } else {
                        this.snackbar = {
                            show: true,
                            color: 'error',
                            text: 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.'
                        }
                    }
                })
        },
        /**
         * Resets error messages
         */
        resetErrorHook() {
            this.successMsg = false;
            this.errorPasswordConfirmMsg = false;
        }
    }
}
</script>
