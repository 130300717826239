<template>
    <div id="grabble">
        <TextIcon id="grabble-icon" class="no-select" menu></TextIcon>
        <div id="grabble-hitbox" @click="this.sidebar.toggleSidebar()"></div>
    </div>
</template>

<script>
import TextIcon from '../components/TextIcon.vue';
import SidebarComponent from '../components/Sidebar.vue';

export default {
    name: 'SidebarGrabble',
    props: {
        sidebar: SidebarComponent
    },
    components: {
        TextIcon
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables.scss';

$grabble-size: 10vh;

#grabble {
    position: absolute;
    left: -$grabble-size/3;
    top: 50%;
    transform: translateY(-50%);
    height: $grabble-size;
    width: $grabble-size;
    background-color: $color-gray;
    border-radius: $grabble-size;
}

#grabble-hitbox {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: $grabble-size/3;
    cursor: pointer;
}

#grabble-icon {
    position: absolute;
    left: $grabble-size/12;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3vh;
    color: white;
}

</style>