//import {Map, View} from 'ol';
//import Tile from 'ol/layer/Tile';
//import VectorLayer from 'ol/layer/Vector';
//import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import * as Projection from 'ol/proj';
//import OSM from 'ol/source/OSM';
//import {transformExtent} from 'ol/proj';

//import TrackingDevice from '@/scripts/trackingdevice.js';
//import Coordinates from '@/scripts/coordinates.js';

const _IconColor = {
    none: '#ffffff',
    disqualified: '#BBBBBB',
    red: '#e63c30',
    yellow: '#DBF05E'
};

export default class MapIcon {

    static IconColor = _IconColor;

    constructor(icon, coordinates, scale, offset = [0.5, 0.5]) {
        this.iconFeature = new Feature({
            geometry: new Point(Projection.fromLonLat([coordinates.lon, coordinates.lat])),
        });
        this.scale = scale;
        this.opacity = 1.0;
        this.offset = offset;
        this.setIcon(icon);
    }

    setCoordinates(coordinates) {
        this.iconFeature.getGeometry().setCoordinates(Projection.fromLonLat([coordinates.lon, coordinates.lat]));
    }

    setIcon(icon, color, opacity = this.opacity, rotation = 0) {
        this.iconFeature.setStyle(
            new Style({
                image: new Icon({
                    anchor: this.offset,
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: icon,
                    color: color,
                    scale: this.scale,
                    opacity: opacity, 
                    rotation: rotation * Math.PI / 180, 
                }),
            })
        );
        this.color = color;
        this.icon = icon;
        this.opacity = opacity;
    }

    setColor(color) {
        this.setIcon(this.icon, color);
    }

    setOpacity(opacity) {
        this.setIcon(this.icon, this.color, opacity);
    }
}