<template>
    <div id="run-dropdown-menu-container">
        <div id="run-dropdown-menu" class="no-select">
            <RunSelectorEntry :title="activeRunSelectTitle" :number="parseInt(active?.name ?? 0)" :key="active" noangle></RunSelectorEntry>
        </div>
        <div id="run-dropdown-list" class="no-select">
            <div id="run-dropdown-list-container" ref="dropdowncontainer">
                <RunSelectorEntry v-for="run in runs" @click="setActiveRun(run)" title="" :number="parseInt(run.name)" :key="run" noangle>
                    <v-row class="d-flex align-center align-self-center">
                        <v-btn class="mr-3" v-if="isLoggedIn && run.start == null && run.end == null" @click="startRun(run)" color="white" icon max-height="30" max-width="30"><v-icon>mdi-play</v-icon></v-btn>
                        <v-btn class="mr-3" v-else-if="isLoggedIn && run.end == null" @click="stopRun(run)" color="red" icon max-height="30" max-width="30"><v-icon>mdi-stop</v-icon></v-btn>
                        <p>{{ displayStartEndTimeForRun(run) }}</p>
                    </v-row>
                </RunSelectorEntry>
            </div>
            <RunSelectorEntry v-if="isLoggedIn" :key="this" @click="this.addRun()" title="Lauf hinzufügen..." nonumber noangle></RunSelectorEntry>
        </div>
    </div>

    <v-dialog
        v-model="confirmEndRunDialog"
        persistent
        max-width="600"
        >
        <v-card class="pa-3">
            <v-card-title class="text-h5">
            Soll der Lauf #{{ runToStop?.name }} wirklich beendet werden?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="confirmEndRunDialog = false"
            >
                Abbrechen
            </v-btn>
            <v-btn
                color="green darken-1"
                text
                @click="confirmEndRunDialog = false; stopRun(runToStop)"
            >
                Bestätigen
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import RunSelectorEntry from '@/components/RunSelectorEntry.vue';
import BackendApi from '@/scripts/BackendApi';
import UserCheck from '@/scripts/UserCheck';
import {roles} from '@/types/Role';
import WatchView from '@/views/WatchView.vue';

export default {
    mixins: [UserCheck],
    name: 'RunSelector',
    props: {
        msg: String,
        watchView: WatchView

    },
    components: {
        RunSelectorEntry,
    },
    data() {
        return {
            addingEnabled: true,
            runs: [],
            active: null, 
            roles: roles,
            confirmEndRunDialog: false,
            runToStop: null,
            snackbar: {
                show: false,
                color: 'error',
                text: ''
            }
        }
    },
    computed: {
        activeRunSelectTitle() {
            return this.active ? this.displayStartEndTimeForRun(this.active) : "Lauf wählen...";
        },
    }, 
    mounted() {
        this.fetchRuns();
    },
    methods: {
        async fetchRuns(){
            const id = this.$route.query.id;
            this.runs = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/runs/' + id))?.data;
            this.runs.sort(this.compare);
        },
        addRun() {
            //this.runs.push({ title: title, number: this.runs.length+1 });
            this.createRun();
        },
        setActiveRun(entry) {
            this.active = entry;
            this.watchView.changeActiveRun(this.active);
        },
        enableAdding(b) {
            this.addingEnabled = b;
        },
        startRun(run) {
            // Send Activation Request to Server
            BackendApi
                .get(process.env.VUE_APP_BACKEND_URL + '/api/runs/start/' + run.id)
                .then(() => {
                    this.fetchRuns();
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.snackbar.show = true;
                    this.snackbar.color = 'error';
                    this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server';
                })
        },
        confirmStopRun(run) {
            this.runToStop = run;
            this.confirmEndRunDialog = true;
        },
        stopRun(run) {
            BackendApi
                .get(process.env.VUE_APP_BACKEND_URL + '/api/runs/end/' + run.id)
                .then(() => {
                    this.fetchRuns();
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.snackbar.show = true;
                    this.snackbar.color = 'error';
                    this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server';
                })
        },

        findRunById(runId) {
            let result = null;
            this.runs.forEach((run) => {
                if (runId === run.id) {
                    result = run;
                }
            });
            return result;
        },

        updateRun(updatedRun) {	
            const run = this.findRunById(updatedRun.id);
            // if the run was not found, it is a new run and has to be inserted 
            if (!run) {
                this.runs.push(updatedRun);
                return;
            }
            
            if (updatedRun.start) {
                run.start = updatedRun.start;
            } else if (updatedRun.end) {
                run.end = updatedRun.end;
            }
        },

        
        createRun(){
            BackendApi
                .post(process.env.VUE_APP_BACKEND_URL + '/api/runs', {
                    name: '' + (this.runs.length + 1),
                    scheduledStart: new Date().toISOString(),
                    regattaId: parseInt(this.$route.query.id),
                })
                .then(async () => {
                    await this.fetchRuns();
                    this.$refs.dropdowncontainer.scrollTop = 10000000;
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.snackbar.show = true;
                    this.snackbar.color = 'error';
                    this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server';
                })
        },

        compare( a, b ) {
            if ( parseInt(a.name) < parseInt(b.name) ){
                return -1;
            }
            if ( parseInt(a.name) > parseInt(b.name) ){
                return 1;
            }
            return 0;
        },

        formatTime(date) {
            if (date == null) {
                return "";
            }
            const d = new Date(date);
            let dateString = d.toLocaleString("de-DE", { timeZone: "Europe/Berlin", minimumIntegerDigits: 2 })
            
            // remove date (return only time) and seconds 
            return dateString.substring(dateString.indexOf(",") + 2).substring(0, 5);
        },

        formatDate(date) {
            if (date == null) {
                return "";
            }
            const d = new Date(date);
            let dateString = d.toLocaleString("de-DE", { timeZone: "Europe/Berlin", minimumIntegerDigits: 2 })
            
            // remove time (return only date) 
            return dateString.substring(0, dateString.indexOf(","));
        },

        displayStartEndTimeForRun(run) {
            let resultString = ""; 
            if (run.start != null) {
                resultString += this.formatDate(run.start) + ": ";
                resultString += this.formatTime(run.start);
            } 
            if (run.end != null) {
                resultString += " - " + this.formatTime(run.end);
            } else if (run.start != null){
                resultString += " - Jetzt";
            }
            return resultString;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/RunSelector.scss';

</style>