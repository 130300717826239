<template>
    <v-container class="d-flex flex-column" style="flex: 1">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" xl="4">
                <div class="text-h4 mb-6">Login</div>
                <v-text-field
                    label="E-Mail"
                    v-model="email"
                    :error-messages="formErrors.email"
                    @update:modelValue="resetError('email')"
                ></v-text-field>
                <v-text-field
                    type="password"
                    label="Passwort"
                    v-model="password"
                    :error-messages="formErrors.password"
                    @update:modelValue="resetError('password')"
                    @keydown.enter="login"
                ></v-text-field>
                <div><router-link :to="{ name: 'forgotPassword' }">Passwort vergessen?</router-link></div>
                <v-btn class="mt-4" color="primary" @click="login">Login</v-btn>
                <v-alert class="mt-5" type="error" density="comfortable" variant="tonal" v-if="errorUnauthorizedMsg">
                    E-Mail und/oder Passwort falsch.
                </v-alert>
            </v-col>
        </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import form from '@/scripts/Form';
import axios from 'axios';
import userCheck from "@/scripts/UserCheck";

export default {
    name: 'LoginView',
    mixins: [form, userCheck],
    data: () => ({
        email: null,
        password: null,
        formErrors: {},
        errorUnauthorizedMsg: false,
        snackbar: {
            show: false,
            color: 'error',
            text: ''
        }
    }),
    beforeMount() {
        if (this.isLoggedIn) {
            this.$router.push({ name: 'home' });
        }
    },
    methods: {
        async login() {
            axios
                .post(process.env.VUE_APP_BACKEND_URL + '/api/auth/login', {
                    email: this.email,
                    password: this.password,
                })
                .then((response) => {
                    if (response.data.access_token && response.data.refresh_token) {
                        this.accessToken = response.data.access_token;
                        this.refreshToken = response.data.refresh_token;
                    }
                    this.$router.push({ name: 'home' });
                })
                .catch((error) => {
                    if (error.response?.data.message === 'Unauthorized') {
                        this.errorUnauthorizedMsg = true;
                        this.password = null;
                    }

                    throw error;
                })
                .catch(this.handleAxiosFormError)
                .catch(() => {
                    this.snackbar.show = true;
                    this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server.';
                }); 
        },

        resetErrorHook() {
            this.errorUnauthorizedMsg = false;
        }
    },
}
</script>
