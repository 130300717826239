<template>
    <v-container fluid class="px-0 py-0 d-flex flex-column" style="flex: 1" ref="map-container">
        <v-row no-gutters style="flex: 1">
            <v-col :cols="showSideBar ? 10 : 12" class="fill-height position-relative py-0 px-0">
                <div class="fill-height" ref="map"></div>
                <div class="slider-wrapper position-absolute">
                    <v-slider
                        v-model="sliderValue"
                        :min="sliderMin"
                        :max="sliderMax"
                        :step="1"
                        thumb-label
                    >
                        <template v-slot:thumb-label>
                            <span class="text-no-wrap">
                                <span v-show="sliderDifferenceHours > 0"><span
                                    v-show="sliderDifferenceHours < 10">0</span>{{ sliderDifferenceHours }}:</span>
                                <span v-show="sliderDifferenceMinutes < 10">0</span>
                                <span>{{ sliderDifferenceMinutes }}:</span>
                                <span v-show="sliderDifferenceSeconds < 10">0</span>
                                <span>{{ sliderDifferenceSeconds }}</span>
                            </span>
                        </template>
                    </v-slider>
                </div>
                <div class="info-wrapper position-absolute px-4 py-4" v-if="highlightedShip">
                    <p class="mb-2">Name: {{ highlightedShip.name }}</p>
                    <p class="mb-2">Heading: {{ highlightedShip.bearing }}</p>
                    <p>Speed: {{ highlightedShip.speed }}</p>
                </div>
            </v-col>
            <v-col cols="2" v-show="showSideBar" class="watch-sidebar">
                <watch-side-bar :ships="ships" @highlight="(id) => this.shipHighlightId = id"></watch-side-bar>
            </v-col>
        </v-row>
        <v-container>
            <v-row>
                <!-- TODO: Replace with real buttons -->
                <v-col>
                    <button @click="sliderValue = sliderValue + 10000">skip forward</button>
                    <button class="ml-4" @click="sliderValue = sliderValue - 10000">skip backwards</button>
                    <button class="ml-4" @click="fullscreen">fullscreen</button>
                    <button class="ml-4" @click="showSideBar = !showSideBar">sidebar</button>
                    <button class="ml-4"
                            @click="ships[0].lat = ships[0].lat - 0.00001; ships[0].lng = ships[0].lng + 0.00001">test
                    </button>
                    <button :class="{ 'text-red': live }" class="ml-4" @click="live = !live">live</button>

                    <span class="ml-4">
                        <span class="text-no-wrap">
                            <span v-show="sliderDifferenceHours > 0"><span
                                v-show="sliderDifferenceHours < 10">0</span>{{ sliderDifferenceHours }}:</span>
                            <span v-show="sliderDifferenceMinutes < 10">0</span>
                            <span>{{ sliderDifferenceMinutes }}:</span>
                            <span v-show="sliderDifferenceSeconds < 10">0</span>
                            <span>{{ sliderDifferenceSeconds }}</span>
                        </span>
                    </span>

                    <v-btn
                        class="ml-4"
                        @click="currentRun = run"
                        color="success"
                        v-for="run in runs"
                        :key="run.id"
                    >Start {{ run.name }}
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import OSM from 'ol/source/OSM';
import {isProxy, toRaw} from 'vue';
import Feature from 'ol/Feature.js';
import {io} from 'socket.io-client';
import Point from 'ol/geom/Point.js';
import * as Projection from 'ol/proj';
import {Icon, Style} from 'ol/style.js';
import VectorSource from 'ol/source/Vector.js';
import WatchSideBar from '@/components/WatchSideBar';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';

export default {
    name: 'WatchView',
    components: {WatchSideBar},
    data: () => ({
        map: null,
        ships: [],
        runs: [],
        currentRun: null,
        shipHighlightId: null,
        lat: 51.7240,
        lng: 9.9638,
        zoom: 17,
        regattaId: 2,

        live: false,
        liveInterval: null,

        sliderValue: 0,
        sliderMin: null,
        sliderMax: null,

        showSideBar: false,
        openSideBar: [],

        socket: null,
        connectionId: null,
        skipOne: false,
        ignoreResponses: true,
    }),
    mounted() {
        // Connect to socket
        this.socket = io('ws://localhost:3000');

        this.socket.on('positions', (message) => {
            if (!this.responseHash || this.responseHash === message.responseHash) {
                this.ships = message.positions;

                if (!this.ignoreResponses) {
                    this.skipOne = true;
                    this.sliderValue = message.queryTimestamp;
                }
            }
        });

        this.socket.on('connection', (message) => {
            if (message.id) {
                this.connectionId = message.id;
                this.socket.emit('regattaId', {id: this.connectionId, regattaId: this.regattaId})
            }
        });

        this.socket.on('runs', (response) => {
            this.runs = response;
        });

        // Create map object
        this.map = new Map({
            target: this.$refs['map'],
            view: new View({
                zoom: this.zoom,
                center: Projection.fromLonLat([this.lng, this.lat]),
                constrainResolution: true
            }),
            controls: []
        });

        // Add layers from initial data
        this.map.addLayer(new TileLayer({source: new OSM()}));
        this.shipLayers.forEach(layer => {
            this.map.addLayer(layer);
        });

        // Reset map on fullscreen change
        document.addEventListener('fullscreenchange', () => {
            this.showSideBar = false;
            this.map.render();
        });

        // Event listener to store the last clicked ship
        this.map.on('click', event => {
            let feature = this.map.forEachFeatureAtPixel(event.pixel, feature => feature);

            if (feature && isProxy(feature)) {
                let ship_id = Number((toRaw(feature).values_.id ?? '').replace('ship_', ''));

                if (ship_id && !isNaN(ship_id)) {
                    this.shipHighlightId = ship_id
                }
            }
        });
    },
    methods: {
        /**
         * Request fullscreen window for map
         */
        fullscreen() {
            toRaw(this.$refs['map-container']).$el.requestFullscreen();
        }
    },
    computed: {
        /**
         * Calculates the difference between the min and the current value of the slider in seconds
         * @returns {number}
         */
        sliderDifference() {
            let diff = Math.floor((this.sliderValue - this.sliderMin) / 1000);

            if (diff < 0) {
                return 0;
            }

            return diff;
        },
        sliderDifferenceSeconds() {
            return this.sliderDifference % 60;
        },
        sliderDifferenceMinutes() {
            return ((this.sliderDifference - this.sliderDifferenceSeconds) / 60) % 60;
        },
        sliderDifferenceHours() {
            return (this.sliderDifference - this.sliderDifferenceSeconds - this.sliderDifferenceMinutes * 60) / 3600;
        },
        /**
         * Return the map ship layers based on the current ship data
         * @returns {*[]}
         */
        shipLayers() {
            if (!this.ships) {
                return [];
            }

            return this.ships.reduce((acc, ship) => {
                // First add the marker for the ship
                acc.push(new VectorLayer({
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new Point(Projection.fromLonLat([ship.longitude, ship.latitude])),
                                id: 'ship_' + ship.id
                            })
                        ]
                    }),
                    style: new Style({
                        image: new Icon({
                            opacity: ship.id === this.shipHighlightId ? 1 : 0.6,
                            src: 'data:image/svg+xml;utf8,' + '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path fill="' + ship.color + '" d="M 4 13.5 L 11 4 V 13.5 H 4 M 12.5 13.5 C 13.85 9.75 13.67 4.71 12.5 2 C 17.26 2.54 20.9 8.4 19.96 13.5 H 12.5 M 21.1 17.08 C 20.69 17.72 20.21 18.27 19.65 18.74 C 16 21 16 21 12 21 C 8 21 8 21 4.3 18.74 C 3.16 17.8 2.3 16.46 2 15 H 21.94 C 21.78 15.75 21.5 16.44 21.1 17.08 Z"/></svg>',
                        })
                    })
                }));

                // Then add an offset and rotated chevron on the same position
                acc.push(new VectorLayer({
                    source: new VectorSource({
                        features: [
                            new Feature({
                                geometry: new Point(Projection.fromLonLat([ship.longitude, ship.latitude])),
                                id: 'ship_chevron_' + ship.id
                            })
                        ]
                    }),
                    style: new Style({
                        image: new Icon({
                            opacity: ship.id === this.shipHighlightId ? 1 : 0.6,
                            anchor: [0.5, 1.25],
                            src: 'data:image/svg+xml;utf8,' + '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path fill="' + ship.color + '" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/></svg>',
                            rotation: ship.bearing * Math.PI / 180,
                        })
                    })
                }));

                return acc;
            }, []);
        },
        /**
         * Return the current highlighted ship
         * @returns {{lng: number, color: string, bearing: number, name: string, id: number, lat: number, speed: number}}
         */
        highlightedShip() {
            if (!this.ships) {
                return [];
            }

            return this.ships.find(ship => {
                return ship.id === this.shipHighlightId;
            })
        }
    },
    watch: {
        zoom(value) {
            this.map.getView().setZoom(value);
        },
        lat(value) {
            this.map.getView().setCenter(Projection.fromLonLat([this.lng, value.lat]));
        },
        lng(value) {
            this.map.getView().setCenter(Projection.fromLonLat([value.lng, this.lat]));
        },
        currentRun() {
            this.sliderMin = new Date(this.currentRun.start).getTime();
            this.sliderValue = new Date(this.currentRun.start).getTime();
            this.sliderMax = new Date(this.currentRun.end).getTime();
        },
        /**
         * Watcher to make ships reactive by replacing the layers on change
         */
        shipLayers: {
            handler(layers) {
                // Remove all layers with features with id matching "ship_*"
                this.map.getAllLayers().forEach(layer => {
                    if (layer !== undefined) {
                        let features = Object.values(toRaw(layer.getSource().featuresRtree_?.items_) ?? {});

                        if (features.find(feature => (toRaw(feature).value?.values_?.id ?? '').includes('ship_'))) {
                            this.map.removeLayer(layer);
                        }
                    }
                });

                layers.forEach(layer => {
                    this.map.addLayer(layer);
                });
            },
            deep: true,
        },
        /**
         * Watcher to start and stop an interval that keeps the "timer" live
         * @param value
         */
        live(value, oldValue) {
            if (value && value !== oldValue) {
                this.socket.emit('live', {regattaId: this.regattaId, id: this.connectionId});

                const start = this.runs.reduce((prev, curr) => prev.start < curr.start ? prev : curr, 0)?.start;
                this.sliderMin = new Date(start.replace('Z', '')).getTime();

                this.liveInterval = setInterval(() => {
                    let now = new Date().getTime();
                    //
                    this.sliderMax = now;
                    this.sliderValue = now;
                }, 1000);
            } else if (this.liveInterval) {
                clearInterval(this.liveInterval);
                this.socket.emit('liveStop', {id: this.connectionId});
            }
        },
        async sliderValue() {
            if (!this.live && !this.skipOne) {
                this.ignoreResponses = true;
                this.responseHash = crypto.randomUUID();
                await this.socket.emit('replayStop', {id: this.connectionId})
                await this.socket.emit('replayStart', {
                    replayTime: this.sliderValue,
                    runId: this.currentRun?.id,
                    id: this.connectionId,
                    responseHash: this.responseHash
                })
                this.ignoreResponses = false;
            }
            this.skipOne = false;
        }
    }
}
</script>

<style lang="scss">
.slider-wrapper {
    left: 32px;
    right: 32px;
    bottom: 0;
}

.info-wrapper {
    left: 32px;
    top: 32px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}
</style>
