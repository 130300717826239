<template>
    <div id="container">
        <div id="map-container">
            <MapComponent ref="map" :lon="0" :lat="0" :deviation="0.03"/>
        </div>

        <div id="player">

            <div id="player-range-container">
                <v-slider
                    v-model="sliderValue"
                    color=#2B2B2B
                    thumb-color="#2B2B2B"   
                    thumb-label="always"
                    :disabled="activeRun === null"
                    :min="sliderMin"
                    :max="sliderMax"
                >
                    <template v-slot:thumb-label>
                        {{ activeRun? formatTime(sliderValue): "" }}
                    </template>
                </v-slider>
            </div>

            <div id="player-bar">
                <div id="player-bar-controls">
                    <div @click="togglePlayback()" class="player-bar-control no-select">
                        <TextIcon class="player-bar-play-icon" ref="playbutton" play/>
                    </div>
                </div>
                <div class="map-zoom-btn">
                    <!--<Button style="width: 100%;" @click="this.toggleZoomBehaviour()" text="Zoom manuell" ref="zoombtn"></Button>-->
                    <v-btn style="height: 100%;" @click="this.toggleZoomBehaviour()">
                        <span ref="zoombtn">Zoom manuell</span>
                    </v-btn>
                </div>
            </div>

        </div>

        <SidebarComponent ref="sidebar">
            <div id="sidebar-flex">
                <RunSelector :watchView="this" ref="runSelector"></RunSelector>
                <div id="sidebar-flex-shipinspector">
                    <ShipInspector :watchView="this" ref="shipinspector" :activeRun="activeRun" @disqualified="updateDisqualifiedShips"></ShipInspector>
                </div>
            </div>
        </SidebarComponent>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import MapComponent from '../components/Map.vue';
import SidebarComponent from '../components/Sidebar.vue';
import RunSelector from '../components/RunSelector.vue';
import TextIcon from '../components/TextIcon.vue';
import ShipInspector from '../components/ShipInspector.vue';
//import Button from '../components/Button.vue';
import {io} from 'socket.io-client';
import BackendApi from '@/scripts/BackendApi';

import Ship from '@/scripts/ship.js';
import Ton from '@/scripts/ton.js';
import Coordinates from '@/scripts/coordinates';

export default {
    name: 'WatchView',
    components: {
        MapComponent,
        SidebarComponent,
        RunSelector,
        TextIcon,
        ShipInspector
    },
    data() {
        return {
            regattaId: -1,
            regatta: null,
            connectionId: -1,
            isPlaying: false,
            zoomAuto: true,
            isLive: false,
            ships: [],
            tons: [],
            activeRun: null,
            sliderMin: null,
            sliderMax: null,
            sliderValue: null,
            responseHash: null, 
            skipNextSliderValueChange: false,
            ignoreResponsesDueToCustomSliderChange: false,
            snackbar: {
                show: false,
                color: 'error',
                text: ''
            }
        }
    },
    async mounted() {
        this.regattaId = this.$route.query.id;
        this.$refs.sidebar.toggleSidebar();

        await this.init();
        this.$refs.map.setCenter(this.regatta.longitude, this.regatta.latitude);
    },
    methods: {
        // ===================== INIT =====================================================================================
        async init() {

            // fetch init data (map coordinates, registered ships, ...)
            await this.fetchInitData();

            // initilize map
            // construct ships
            
            // add ships to map and to sidebar
            for (let i = 0; i < this.ships.length; ++i) {
                let ship = this.ships[i];
                ship.setCoordinates(new Coordinates(this.regatta.longitude, this.regatta.latitude));
                ship.mapIcon.setOpacity(0.0); 
                this.$refs.map.addIcon(ship.mapIcon);
            }

            for (let i = 0; i < this.tons.length; ++i) {
                let ton = this.tons[i];
                // if the name of the ton contains Start change the color to green otherwise blue 
                if (ton.name.includes("Start")) {
                    ton.mapIcon.setColor("#00ff00")
                } else {
                    ton.mapIcon.setColor("#8888ff")
                }
                this.$refs.map.addIcon(ton.mapIcon);
            }
        },
        async fetchInitData() {

            // Find Regatta Location
            try {
                const regattas = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/regatta/'))?.data;
                for (let i = 0; i < regattas.length; ++i) {
                    const regatta = regattas[i];
                    if (regatta.id == this.regattaId) {
                        this.regatta = regatta;
                        break;
                    }
                }
            } catch {
                this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
            }

            // Fetch Ships in Regatta and add them to the Ships list. All updates will only modify
            // these ships and not replace them.
            try {
                const shipsInRegatta = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/ships/regatten/' + this.regattaId))?.data;
                for (let i = 0; i < shipsInRegatta.length; ++i) {
                    const shipStub = shipsInRegatta[i];
                    const ship = new Ship(shipStub.id, shipStub.name, shipStub.color);
                    this.ships.push(ship);
                }
            } catch {
                this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
            }

            // Fetch Tons in Regatta and add them to the Tons list. All updates will only modify
            // these Tons and not replace them.
            try {
                const tonsInRegatta = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/barrels/regatten/' + this.regattaId))?.data;
                for (let i = 0; i < tonsInRegatta.length; ++i) {
                    const tonStub = tonsInRegatta[i];
                    const ton = new Ton(tonStub.id, tonStub.name);

                    // fetch the static positions of the tons 
                    const tonPositions = (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/barrels/positions/' + tonStub.id))?.data;
                    if (tonPositions && tonPositions.length > 0) {
                        const tonPosition = tonPositions[0];
                        ton.setCoordinates(new Coordinates(tonPosition.longitude, tonPosition.latitude));
                    }

                    this.tons.push(ton);
                }
            } catch {
                this.displaySnackbar('Fehler bei der Kommunikation mit dem Server', 'error');
            }

            // ===================== SOCKET ========================================================================================
            this.socket = io(process.env.VUE_APP_BACKEND_URL, { secure: true });
            this.socket.on('positions', (message) => {
                if (!this.responseHash || this.responseHash === message.responseHash) {
                    for (let i = 0; i < message.positions.length; ++i) {
                        const shipStub = message.positions[i];
                        const ship = this.getShipById(shipStub.shipId);
                        if (ship != null) {
                            ship.setCoordinates(new Coordinates(shipStub.longitude, shipStub.latitude));
                            ship.speed = shipStub.speed;
                            ship.setBearing(shipStub.bearing);
                        }
                    }
                    if (message.positions.length > 0 && this.ships[0].mapIcon.opacity == 0.00) {
                        this.ships.forEach(ship => {
                            ship.mapIcon.setOpacity(1)
                            this.$refs.map.addIcon(ship.bearingMapIcon);
                        }); 
                    }
                    if (this.isLive && !this.activeRun.end) {
                        this.sliderMax = new Date().getTime();
                        this.skipNextSliderValueChange = true;
                        this.sliderValue = this.sliderMax;
                    } else {
                        if (!this.ignoreResponsesDueToCustomSliderChange) {
                            this.skipNextSliderValueChange = true;
                            this.sliderValue = message.queryTimestamp;
                        }
                    }
                    if (this.zoomAuto) {
                        this.$refs.map.fitZoom();
                    }
                }
            });
            this.socket.on('connection', (message) => {
                if (message.id) {
                    this.connectionId = message.id;
                    this.socket.emit('regattaId', {id: this.connectionId, regattaId: this.regattaId})
                }
            });
            this.socket.on('run', (run) => {
                this.$refs.runSelector.updateRun(run);
                if (run.end && run.id == this.activeRun?.id) {
                    this.stopLiveStream();
                }
                if (run.disqualifiedShips && run.id == this.activeRun?.id) {
                    this.updateDisqualifiedShips(JSON.parse(run.disqualifiedShips));
                }
            })
        },

        // ===================== CHANGE RUN =====================================================================================
        changeActiveRun(run) {
            if (this.activeRun) {
                this.stopLiveStream();
                this.stopReplay();
            }
            this.activeRun = run;
            this.updateDisqualifiedShips(run.disqualifiedShips);

            this.sliderMin = run.start ? new Date(run.start).getTime() : new Date().getTime();
            if (run.end == null) {
                this.startLiveStream(); 
            } else {
                // update the slider 
                this.sliderMax = new Date(run.end).getTime();
                this.skipNextSliderValueChange = true;
                this.sliderValue = this.sliderMin;
                this.startReplay(); 
            }
        },

        updateDisqualifiedShips(disqualifiedShips) {
            for (let i = 0; i < this.ships.length; ++i) {
                let ship = this.ships[i];
                if (disqualifiedShips.includes(ship.id)) {
                    ship.setDisqualified(true);
                } else {
                    ship.setDisqualified(false);
                }
            }
        },

        startLiveStream() {
            this.isLive = true;
            this.socket.emit('liveStart', {regattaId: this.regattaId, id: this.connectionId});

            // update the slider 
            this.sliderMax = new Date().getTime();
            this.skipNextSliderValueChange = true;
            this.sliderValue = this.sliderMax;

            this.changePlayButton(/* isPlaying= */ true)
        }, 

        stopLiveStream() {
            this.isLive = false;
            this.socket.emit('liveStop', {regattaId: this.regattaId, id: this.connectionId});
            this.changePlayButton(/* isPlaying= */ false)
        },

        startReplay() {
            this.changePlayButton(/* isPlaying= */ true)
            this.socket.emit('replayStart', {
                replayTime: this.sliderValue,
                runId: this.activeRun?.id,
                id: this.connectionId,
                responseHash: this.responseHash
            });
            this.isPlaying = true;
        }, 

        stopReplay() {
            this.changePlayButton(/* isPlaying= */ false)
            this.socket.emit('replayStop', {id: this.connectionId});
            this.isPlaying = false;
        }, 

        // ===================== CONTROLS =====================================================================================
        toggleZoomBehaviour() {
            this.zoomAuto = !this.zoomAuto;
            if (this.zoomAuto) {
                this.$refs.zoombtn.innerText = "Zoom manuell";
            } else {
                this.$refs.zoombtn.innerText = "Zoom automatisch";
            }
        },

        togglePlayback() {
            if (this.isLive) {
                this.stopLiveStream();    
            } else {
                if (this.isPlaying) {
                    this.stopReplay(); 
                } else {
                    this.startReplay(); 
                }
            }
        },

        changePlayButton(playState) {
            if (playState) {
                this.$refs.playbutton.setIcon(this.$refs.playbutton.icons.pause);
            } else {
                this.$refs.playbutton.setIcon(this.$refs.playbutton.icons.play);
            }
        }, 

        // ===================== HELPER METHODS =====================================================================================
        getShipById(id) {
            for (let i = 0; i < this.ships.length; ++i) {
                const ship = this.ships[i];
                if (ship.id == id) {
                    return ship;
                }
            }
            return null;
        },

        formatTime(timeInSecondsSince1970) {
            const date = new Date(timeInSecondsSince1970);
            return date.toLocaleTimeString();
        },

        displaySnackbar(message, color) {
            this.snackbar = {
                message: message,
                color: color,
                show: true
            }
        },
    }, 
    watch: {
        async sliderValue() {
            // was slider moved by user when not live 
            if (!this.isLive && !this.skipNextSliderValueChange) {
                this.ignoreResponsesDueToCustomSliderChange = true;
                this.responseHash = crypto.randomUUID();
                this.stopReplay(); 
                this.startReplay();
                this.ignoreResponsesDueToCustomSliderChange = false;
            } 
            // was slider moved by user when live
            if (this.isLive && !this.skipNextSliderValueChange) {
                this.stopLiveStream(); 
                this.startReplay(); 
            }

            // hit the end of the slider -> start live stream
            if (!this.isLive && this.sliderValue == this.sliderMax) {
                this.stopReplay();
                if (!this.activeRun.end) {
                    this.startLiveStream(); 
                }
            }

            this.skipNextSliderValueChange = false;
        }
    }, 
}
</script>

<style lang="scss" scoped>
@import '../styles/WatchView.scss';

#container {
    margin-top: $navbar-height;
}

.map-zoom-btn {
    position: absolute;
    left: 1vw;
    top: 50%;
    transform: translateY(-50%);
    min-width: 5vw;
    height: 60%;
}

#sidebar-flex {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    flex-direction: column;
}

#sidebar-flex-shipinspector {
    height: 100%;
    overflow: auto;
}

#player {
    position: absolute;
    height: 5vh;
    width: calc(100% - $sidebar-width-imploded);
    bottom: 0;
}

#player-bar {
    width: 100%;
    height: 100%;
    background-color: $color-gray;
}

#player-bar-controls {
    position: absolute;
    height: 100%;
    width: 5vw;
    left: 50%;
    transform: translateX(-50%);
}

.player-bar-control {
    height: 5vh;
    width: 5vh;
    color: $color-text;
    cursor: pointer;
}

.player-bar-play-icon {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20pt;
}

#player-range-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    width: 90%;
}

</style>
