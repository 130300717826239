<template>
    <v-card>
        <v-container>
            <v-card-title class="headline">Position Anpassen für Regatta {{ regattaName }}</v-card-title>
            <v-card-text>
                <draggable 
                    v-model="positions"
                    @start="drag=true" 
                    @end="drag=false" 
                    item-key="id"
                >
                <template #item="{element, index}">
                    <v-card class="my-5">
                        <v-card-title> {{ (index + 1) + " " +  element.name }}</v-card-title>
                    </v-card>
                </template>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeAction">Abbrechen</v-btn>
                <v-btn color="primary" text @click="savePositions">Speichern</v-btn>
            </v-card-actions>
        </v-container>
    </v-card>


    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:actions>
            <v-icon icon="mdi-close" @click="snackbar.show = false"></v-icon>
        </template>
    </v-snackbar>
</template>
<script>
import BackendApi from '@/scripts/BackendApi';
import draggable from 'vuedraggable';

export default {
    data() {
        return {
            positions: [], 
            drag: false,
            snackbar: {
                show: false,
                color: 'error',
                text: ''
            }
        }
    }, 
    props: {
        regattaName: {
            type: String, 
            required: true
        }, 
        runId: {
            type: Number, 
            required: true
        }, 
        closeAction: {
            type: Function, 
            required: true
        },
        successAction: {
            type: Function, 
            required: true, 
        }
    }, 
    async mounted() {
        this.positions = await this.fetchPositions(); 
        console.log(this.positions)
    },
    methods: {
        async savePositions() {
            try {
                await BackendApi.post(process.env.VUE_APP_BACKEND_URL + '/api/runs/' + this.runId + '/positions', this.positions)
            } catch {
                this.snackbar.show = true;
                this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server' 
                this.snackbar.color = 'error';
                return;
            }
            this.successAction();
        }, 
        async fetchPositions() {
            try {
                return (await BackendApi.get(process.env.VUE_APP_BACKEND_URL + '/api/runs/' + this.runId + '/positions'))?.data;
            } catch {
                this.snackbar.show = true;
                this.snackbar.text = 'Fehler bei der Kommunikation mit dem Server' 
                this.snackbar.color = 'error';
                return [];
            }
        },
    }, 
    components: {
        draggable
    }
}
</script>