<template>
    <v-container fluid class="pa-0 nav-container">
        <v-container class="py-0">
            <v-row class="ma-0">
                <v-col cols="12" class="py-0 d-flex justify-end justify-xl-space-between nav-col">
                    <div class="d-flex d-xl-none align-center">
                        <v-icon icon="mdi-menu" @click="nav = true"></v-icon>
                    </div>
                    <div class="d-none d-xl-flex font-weight-medium align-center">
                        <router-link :to="{ name: 'regatta-overview' }" class="nav-router-link d-flex">
                            <v-icon icon="mdi-sail-boat"></v-icon>
                            <span class="ml-1">Regattenübersicht</span>
                        </router-link>
                        <router-link v-if="isLoggedIn && hasRoles([roles.admin])" :to="{ name: 'rpiManagement' }"
                                     class="nav-router-link d-flex">
                            <v-icon class="ml-12" icon="mdi-raspberry-pi"></v-icon>
                            <span class="ml-1">Pi Verwaltung</span>
                        </router-link>
                    </div>
                    <div class="d-none d-xl-flex font-weight-bold align-center">
                        <router-link v-if="isLoggedIn && hasRoles([roles.admin])" :to="{ name: 'userManagement' }"
                                     class="nav-router-link d-flex">
                            <v-icon class="mr-1" icon="mdi-account-cog"></v-icon>
                            <span class="mr-12">Benutzerverwaltung</span>
                        </router-link>
                        <router-link v-if="!isLoggedIn" :to="{ name: 'login' }" class="nav-router-link d-flex">
                            <v-icon class="mr-1" icon="mdi-login"></v-icon>
                            <span>Login</span>
                        </router-link>
                        <div v-else @click="logout" class="nav-router-link d-flex">
                            <v-icon class="mr-1" icon="mdi-logout"></v-icon>
                            <span>Logout</span>
                        </div>
                    </div>
                    <div class="nav-logo">
                        <div class="nav-logo-top"></div>
                        <router-link :to="{ name: 'home' }" class="nav-router-link text-">
                            <v-img
                                :src="require('../assets/Regatta.png')"
                                class="mt-3 ml-1"
                                height="70"
                                width="70"
                            ></v-img>
                        </router-link>
                    </div>
                </v-col>
            </v-row>

            <v-navigation-drawer location="right" class="d-xl-none" v-model="nav" temporary>
                <v-list-item
                    :prepend-avatar="require('../assets/Regatta.png')"
                    title="Regatta"
                ></v-list-item>

                <v-divider></v-divider>

                <v-list density="compact" nav>
                    <router-link :to="{ name: 'home' }" class="nav-router-link">
                        <v-list-item title="Startseite" value="home"></v-list-item>
                    </router-link>

                    <router-link :to="{ name: 'regatta-overview' }" class="nav-router-link">
                        <v-list-item title="Regattenübersicht" value="regatta-overview"></v-list-item>
                    </router-link>

                    <router-link v-if="isLoggedIn && hasRoles([roles.admin])" :to="{ name: 'rpiManagement' }"
                                 class="nav-router-link">
                        <v-list-item title="Pi Verwaltung" value="pi-management"></v-list-item>
                    </router-link>

                    <router-link v-if="isLoggedIn && hasRoles([roles.admin])" :to="{ name: 'userManagement' }"
                                 class="nav-router-link">
                        <v-list-item title="Benutzerverwaltung" value="user-management"></v-list-item>
                    </router-link>

                    <router-link v-if="!isLoggedIn" :to="{ name: 'login' }" class="nav-router-link">
                        <v-list-item title="Login" value="login"></v-list-item>
                    </router-link>

                    <v-list-item @click="logout" v-else class="nav-router-link" title="Logout" value="logout"></v-list-item>
                </v-list>
            </v-navigation-drawer>
        </v-container>
    </v-container>
</template>

<script>
import UserCheck from '@/scripts/UserCheck';
import {roles} from '@/types/Role';

export default {
    name: 'NavigationBar',
    mixins: [UserCheck],
    data: () => ({
        nav: false,
        roles: roles
    }),
    methods: {
        logout() {
            this.accessToken = '';
            this.refreshToken = '';
        }
    }
}
</script>

<style lang="scss">
@import './../styles/_variables.scss';

.nav-container {
    position: fixed;
    background-color: $navigation-background;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);
}

.nav-col {
    height: 70px;
    position: relative;
}

.v-list-item-title {
    color: $nav-text-color;
    text-decoration: none;
}

.nav-router-link {
    color: $nav-text-color;
    text-decoration: none;
}

.nav-router-link:hover {
    cursor: pointer;
    color: $color-primary;
}

.nav-logo {
    background-color: $navigation-background;
    height: 140px;
    width: 140px;
    z-index: 10;

    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20px;

    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.15);

    .nav-logo-top {
        background-color: $navigation-background;

        width: 160px;
        height: 70px;
        position: absolute;
        margin-top: -30px;
    }
}

i:hover {
    cursor: pointer;
}
</style>
