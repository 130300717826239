<template>
    <div id="run-dropdown-container">
        <div id="run-dropdown-selector"></div>
        <div id="run-dropdown-angle" ref="angle"></div>
        <div id="run-dropdown-indicator" ref="indicator">
            <span id="run-content" ref="numberspan">Lauf <span id="poundsym">#</span>{{number}}</span>
            <TextIcon v-if="this.nonumber" id="add" addcirc></TextIcon>
        </div>
        <span id="run-dropdown-title" class="d-flex">
            {{ title }}
            <slot></slot>
        </span>
    </div>
</template>

<script>
import TextIcon from '@/components/TextIcon.vue';

export default {
    name: 'RunSelectorEntry',
    props: {
        msg: String,
        title: String,
        nonumber: Boolean,
        color: String,
        noangle: Boolean,
        number: {
            type: Number,
            default() {
                return 0;
            }
        }
    },
    components: {
        TextIcon
    },
    methods: {
        
    },
    mounted() {
        if (this.nonumber) {
            this.$refs.numberspan.innerText= "";
        
        } else if (this.number == 0) {
            this.$refs.numberspan.innerText= "Kein Lauf";
        }
        
        if (this.color) {
            this.$refs.indicator.style.backgroundColor = this.color;
        }

        if (this.noangle) {
            this.$refs.angle.style.display = "none";
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/RunSelectorEntry.scss';

#poundsym {
    font-size: 8pt;
}
</style>